import { useEffect, useState } from "react";
import { endpoints } from "api/endpoints";
import { GET_REQUEST } from "api/api-services";

export const HiCityTechnologyHighlights = () => {
    const [technologyData, setTechnologyData] = useState(null);

    const fetchTechnologyData = async () => {
        try {
            const response = await GET_REQUEST(
                endpoints["technology_highlights_data"]
            );

            if (response?.status) {
                setTechnologyData(response?.data);
            } else {
                throw new Error(
                    response?.message ||
                        `HTTP error! status: ${response.status}`
                );
            }
        } catch (error) {
            console.log(error?.message);
        }
    };

    useEffect(() => {
        fetchTechnologyData();
    }, []);

    return (
        <section className="technology_section bg_secondary">
            <div className="custom-container">
                <div className="row">
                    <div className="col-sm-7 col-12">
                        <div className="tech_img_wrap">
                            <div className="tech_img_lg">
                                <img
                                    className="img-fluid"
                                    src={
                                        technologyData?.data?.[0]?.attributes
                                            ?.HighlightSectionImage?.data
                                            ?.attributes?.url
                                    }
                                    alt=""
                                />
                                <div className="tech_img_sm">
                                    <img
                                        className="img-fluid"
                                        src={
                                            technologyData?.data?.[0]
                                                ?.attributes
                                                ?.HighlightSectionSecondImage
                                                ?.data?.attributes?.url
                                        }
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-5 col-12">
                        <div className="tech_content_wrap">
                            <div className="tech_header">
                                <h3
                                    className="secondary_heading"
                                    dangerouslySetInnerHTML={{
                                        __html: technologyData?.data?.[0]
                                            ?.attributes?.HighlightHeading,
                                    }}
                                ></h3>
                                {/* HiCity <span>calls for</span> high tech features */}
                            </div>
                            <ul className="tech_list">
                                {technologyData?.data?.[0]?.attributes?.HighlightSectionIconText?.map(
                                    (item, index) => (
                                        <li key={index}>
                                            <span className="tech_icon">
                                                <img
                                                    className="img-fluid"
                                                    src={
                                                        technologyData
                                                            ?.data?.[0]
                                                            ?.attributes?.[
                                                            `TechnologyIcon${
                                                                index + 1
                                                            }`
                                                        ]?.data?.attributes?.url
                                                    }
                                                    alt=""
                                                />
                                            </span>
                                            {/* Low-Cost Transportation (LCT) */}
                                            {item?.technologyTitle}
                                        </li>
                                    )
                                )}
                            </ul>
                            <div className="tech_content">
                                <p>
                                    {
                                        technologyData?.data?.[0]?.attributes
                                            ?.HighlightDescription
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
