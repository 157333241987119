import { useEffect, useState } from "react";
import { endpoints } from "api/endpoints";
import { GET_REQUEST } from "api/api-services";

export const HiCityFeatures = () => {
    const [featuresData, setfeaturesData] = useState(null);

    const fetchfeaturesData = async () => {
        try {
            const response = await GET_REQUEST(endpoints["features_data"]);
            if (response?.status) {
                setfeaturesData(response?.data);
            } else {
                throw new Error(
                    response?.message ||
                        `HTTP error! status: ${response.status}`
                );
            }
        } catch (err) {
            console.log(err.message);
        }
    };
    useEffect(() => {
        fetchfeaturesData();
    }, []);

    return (
        <section className="feature_section bg_secondary">
            <div className="custom-container">
                <div className="feature_wrap">
                    <div className="feature_text_wrap">
                        <img
                            className="img-fluid"
                            src={
                                featuresData?.data?.[0]?.attributes
                                    ?.FeatureSectionBackground?.data?.attributes
                                    ?.url
                            }
                            alt=""
                        />
                    </div>
                    <div className="feature_auto_wrap">
                        <img
                            className="img-fluid"
                            src={
                                featuresData?.data?.[0]?.attributes
                                    ?.FeatureSectionBgImageDesktop?.data
                                    ?.attributes?.url
                            }
                            alt=""
                        />
                    </div>
                </div>
                <div className="feature_list_wrap">
                    <ul className="feature_list">
                        {featuresData?.data?.[0]?.attributes?.FeatureSectionData?.map(
                            (item, index) => (
                                <li key={index}>
                                    <div className="fearure_list_img_wrap">
                                        <img
                                            className="img-fluid"
                                            src={
                                                featuresData?.data?.[0]
                                                    ?.attributes?.[
                                                    `FeatureSectionIcon${
                                                        index + 1
                                                    }`
                                                ]?.data?.attributes?.url
                                            }
                                            alt=""
                                        />
                                    </div>
                                    <div className="feature_content">
                                        <div className="top_content">
                                            {item?.FeatureTitle}
                                        </div>
                                        <div className="bottom_content">
                                            {item?.FeatureSubtitle}
                                        </div>
                                    </div>
                                </li>
                            )
                        )}
                    </ul>
                </div>
            </div>
        </section>
    );
};
