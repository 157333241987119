import { useEffect, useState } from "react";
import { endpoints } from "api/endpoints";
import { GET_REQUEST } from "api/api-services";

export const HiCityAssistance = () => {
    const [assistanceData, setAssistanceData] = useState(null);

    const fetchAssistanceData = async () => {
        try {
            const response = await GET_REQUEST(endpoints["assistance_data"]);
            if (response?.status) {
                setAssistanceData(response?.data);
            } else {
                throw new Error(
                    response?.message ||
                        `HTTP error! status: ${response.status}`
                );
            }
        } catch (err) {
            console.log(err.message);
        }
    };

    useEffect(() => {
        fetchAssistanceData();
    }, []);

    return (
        <section className="assistance_section bg_primary">
            <div className="custom-container">
                <div className="row">
                    <div className="col-12 col-sm-8">
                        <div className="assistance_wrap">
                            <div className="assistance_col _left">
                                <div className="img_wrap">
                                    <img
                                        className="img-fluid"
                                        src={
                                            assistanceData?.data?.[0]
                                                ?.attributes
                                                ?.AssistanceSectionIcon1?.data
                                                ?.attributes?.url
                                        }
                                        alt=""
                                    />
                                </div>
                                <div className="assistance_content">
                                    <div className="top_content">
                                        {
                                            assistanceData?.data?.[0]
                                                ?.attributes?.AssistanceHeading
                                        }
                                    </div>
                                    <div className="middle_content">
                                        {
                                            assistanceData?.data?.[0]
                                                ?.attributes
                                                ?.AssistanceSubHeading
                                        }
                                    </div>
                                    <div className="bottom_content">
                                        {
                                            assistanceData?.data?.[0]
                                                ?.attributes
                                                ?.AssistanceDescription
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="seperator" />
                            <div className="assistance_col _right">
                                <div className="img_wrap">
                                    <img
                                        className="img-fluid"
                                        src={
                                            assistanceData?.data?.[0]
                                                ?.attributes
                                                ?.AssistanceSectionIcon2?.data
                                                ?.attributes?.url
                                        }
                                        alt=""
                                    />
                                </div>
                                <div className="assistance_content">
                                    <div className="top_content">
                                        {
                                            assistanceData?.data?.[0]
                                                ?.attributes?.CallUs
                                        }
                                        <br />
                                        {
                                            assistanceData?.data?.[0]
                                                ?.attributes?.CallContact
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-4">
                        <div className="assistance_vehicle">
                            <div className="img_wrap">
                                <img
                                    className="img-fluid"
                                    src={
                                        assistanceData?.data?.[0]?.attributes
                                            ?.AssistanceSectionImage?.data
                                            ?.attributes?.url
                                    }
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
