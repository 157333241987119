import Slider from "react-slick";
import { useEffect, useState } from "react";
//
import { endpoints } from "api/endpoints";
import { GET_REQUEST } from "api/api-services";

export const HiCityQuality = () => {
    const sliderOptions = {
        dots: true,
        arrows: false,
        infinite: false,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                // breakpoint: 10000,
                // settings: "unslick",
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2.4,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2.4,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
        ],
    };

    const [qualityData, setQualityData] = useState(null);

    const fetchQualityData = async () => {
        try {
            const response = await GET_REQUEST(endpoints["quality_data"]);

            if (response?.status) {
                setQualityData(response?.data);
            } else {
                throw new Error(
                    response?.message ||
                        `HTTP error! status: ${response.status}`
                );
            }
        } catch (err) {
            console.log(err.message);
        }
    };

    useEffect(() => {
        fetchQualityData();
    }, []);

    return (
        <section className="quality_section bg_secondary">
            <div className="custom-container">
                <div className="row">
                    <div className="col-12 col-sm-3">
                        <div className="quality_img_wrap">
                            <img
                                className="img-fluid"
                                src={
                                    qualityData?.data?.[0]?.attributes
                                        ?.QualityAssuranceDesktopImage?.data
                                        ?.attributes?.url
                                }
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="col-12 col-sm-9">
                        <div className="quality_list_wrap">
                            <div className="d-lg">
                                <ul className="quality_list">
                                    {qualityData?.data?.[0]?.attributes?.QualityTimeline?.map(
                                        (item, index) => (
                                            <li key={index}>
                                                <div className="quality_list_img_wrap">
                                                    <img
                                                        className="img-fluid"
                                                        src={
                                                            qualityData
                                                                ?.data?.[0]
                                                                ?.attributes?.[
                                                                `QualityAssuranceIcon${
                                                                    index + 1
                                                                }`
                                                            ]?.data?.[0]
                                                                ?.attributes
                                                                ?.url
                                                        }
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="quality_content_wrap">
                                                    <div className="top_content">
                                                        {item?.TimelineTitle}
                                                    </div>
                                                    <div className="bottom_content">
                                                        {item?.TimelineSubtitle}
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    )}
                                </ul>
                            </div>
                            <div className="d-xs">
                                {!!qualityData && (
                                    <Slider
                                        {...sliderOptions}
                                        className="quality_list quality_list_slider"
                                    >
                                        {qualityData?.data?.[0]?.attributes?.QualityTimeline?.map(
                                            (item, index) => (
                                                <li
                                                    key={index}
                                                    className="slick-slide"
                                                >
                                                    <div className="quality_list_img_wrap">
                                                        <img
                                                            className="img-fluid"
                                                            src={
                                                                qualityData
                                                                    ?.data?.[0]
                                                                    ?.attributes?.[
                                                                    `QualityAssuranceIcon${
                                                                        index +
                                                                        1
                                                                    }`
                                                                ]?.data?.[0]
                                                                    ?.attributes
                                                                    ?.url
                                                            }
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="quality_content_wrap">
                                                        <div className="top_content">
                                                            {
                                                                item?.TimelineTitle
                                                            }
                                                        </div>
                                                        <div className="bottom_content">
                                                            {
                                                                item?.TimelineSubtitle
                                                            }
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        )}
                                    </Slider>
                                )}
                            </div>
                            <div className="quality_lg_txt">QUALITY</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
