import { useEffect, useState } from "react";
import { endpoints } from "api/endpoints";
import { GET_REQUEST } from "api/api-services";

export const HiCityBrandVideo = () => {
    const [videoData, setVideoData] = useState(null);
    const featchVideoData = async () => {
        try {
            const response = await GET_REQUEST(endpoints["video_data"]);
            if (response?.status) {
                setVideoData(response?.data);
            } else {
                throw new Error(
                    response?.message ||
                        `HTTP error! status: ${response.status}`
                );
            }
        } catch (err) {
            console.log(err.message);
        }
    };
    useEffect(() => {
        featchVideoData();
    }, []);

    return (
        <section className="brand_video_section">
            <div className="video_wrap">
                {videoData ? (
                    <video
                        className="video_item"
                        autoPlay
                        loop
                        muted
                        playsInline
                    >
                        <source
                            src={
                                videoData?.data?.[0]?.attributes?.VideoSection
                                    ?.data?.attributes?.url
                            }
                            type="video/mp4"
                        />
                    </video>
                ) : (
                    <p>Loading video...</p>
                )}
            </div>
        </section>
    );
};
