import { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/system";
//
import { endpoints } from "api/endpoints";
import { GET_REQUEST } from "api/api-services";
import Broucher from "Pages/components/Broucher";

export const HiCityPowerful = () => {
    const small = useMediaQuery("(max-width: 786px)");

    const [powerfulData, setPowerfulData] = useState(null);
    const [openBrochureForm, setOpenBrochureForm] = useState(false);

    const fatchPowerfulData = async () => {
        try {
            const response = await GET_REQUEST(
                endpoints["powerful_cargo_data"]
            );
            if (response?.status) {
                setPowerfulData(response?.data);
            } else {
                throw new Error(
                    response?.message ||
                        `HTTP error! status: ${response.status}`
                );
            }
        } catch (err) {
            console.log(err?.message);
        }
    };
    useEffect(() => {
        fatchPowerfulData();
    }, []);

    return (
        <>
            <section className="powerful_section bg_secondary">
                <div className="custom-container">
                    <div className="powerful_header">
                        <h4
                            className="secondary_heading"
                            dangerouslySetInnerHTML={{
                                __html:
                                    powerfulData?.data?.[0]?.attributes
                                        ?.PowerfulCargoHeading ||
                                    "HiCity means <span>high range</span>",
                            }}
                        />
                        <p>
                            {
                                powerfulData?.data?.[0]?.attributes
                                    ?.PowerfulCargoSectionDescription
                            }
                        </p>
                        <div className="powerful_btn_wrap">
                            <a
                                className="theme-btn theme-btn-secondary"
                                href={
                                    powerfulData?.data?.[0]?.attributes
                                        ?.PowerfulCargoSectionButtonData
                                        ?.PhoneNumberButtonLink
                                }
                            >
                                <span className="btn_icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={13}
                                        height={14}
                                        viewBox="0 0 13 14"
                                        fill="none"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M2.81868 1.55566C2.86093 2.24789 2.96655 2.92455 3.13555 3.57011L2.29055 4.50344C2.00184 3.57011 1.81875 2.58233 1.75538 1.55566H2.81868ZM9.76029 10.9038C10.3588 11.0905 10.9715 11.2071 11.5911 11.2538V12.4127C10.6616 12.3427 9.76733 12.1405 8.91528 11.8294L9.76029 10.9038ZM3.49372 0H1.02912C0.641828 0 0.324951 0.35 0.324951 0.777778C0.324951 8.08111 5.68369 14 12.2958 14C12.6831 14 13 13.65 13 13.2222V10.5078C13 10.08 12.6831 9.73 12.2958 9.73C11.4227 9.73 10.5706 9.57444 9.78196 9.28667C9.71154 9.25556 9.63408 9.24778 9.56366 9.24778C9.38058 9.24778 9.20454 9.32556 9.0637 9.47333L7.51453 11.1844C5.52173 10.0567 3.88805 8.26 2.87405 6.05889L4.42322 4.34778C4.62039 4.13 4.67672 3.82667 4.59926 3.55444C4.33872 2.68333 4.19789 1.75 4.19789 0.777778C4.19789 0.35 3.88101 0 3.49372 0Z"
                                            fill="currentColor"
                                        />
                                    </svg>
                                    {/* <img
                                    src={
                                        powerfulData?.data?.[0]?.attributes
                                            ?.PowerfulCargoSectionButtonData
                                            ?.PhoneNumberIcon?.data?.attributes
                                            ?.url
                                    }
                                    alt=""
                                /> */}
                                </span>
                                {
                                    powerfulData?.data?.[0]?.attributes
                                        ?.PowerfulCargoSectionButtonData
                                        ?.PhoneNumberButton
                                }
                            </a>
                            <a
                                className="theme-btn theme-btn-primary"
                                href={
                                    powerfulData?.data?.[0]?.attributes
                                        ?.PowefulCargoDownloadSectionData
                                        ?.ButtonMedia?.data?.attributes?.url
                                }
                                download
                                target="_blank"
                                onClick={(event) => {
                                    if (
                                        !!powerfulData?.data?.[0]?.attributes
                                            ?.PowefulCargoDownloadSectionData
                                            ?.ButtonMedia?.data
                                    ) {
                                        event.preventDefault();
                                        setOpenBrochureForm(true);
                                    }
                                }}
                            >
                                <span className="btn_icon">
                                    {/* <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={23}
                                    height={21}
                                    viewBox="0 0 23 21"
                                    fill="none"
                                >
                                    <path
                                        d="M11.838 11.7192V4.26758M7.25464 12.5471H5.42131C5.17819 12.5471 4.94503 12.6344 4.77312 12.7897C4.60122 12.9449 4.50464 13.1555 4.50464 13.3751V16.6869C4.50464 16.9065 4.60122 17.1171 4.77312 17.2724C4.94503 17.4277 5.17819 17.5149 5.42131 17.5149H18.2546C18.4978 17.5149 18.7309 17.4277 18.9028 17.2724C19.0747 17.1171 19.1713 16.9065 19.1713 16.6869V13.3751C19.1713 13.1555 19.0747 12.9449 18.9028 12.7897C18.7309 12.6344 18.4978 12.5471 18.2546 12.5471H16.4213M15.5046 8.40736L11.838 12.5471L8.17131 8.40736M16.4213 15.031H16.4305"
                                        stroke="currentColor"
                                        strokeWidth="1.41935"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg> */}
                                    <img
                                        src={
                                            powerfulData?.data?.[0]?.attributes
                                                ?.PowefulCargoDownloadSectionData
                                                ?.ButtonIcon?.data?.attributes
                                                ?.url
                                        }
                                        alt=""
                                    />
                                </span>
                                {
                                    powerfulData?.data?.[0]?.attributes
                                        ?.PowefulCargoDownloadSectionData
                                        ?.ButtonText
                                }
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <Broucher
                open={openBrochureForm}
                handleClose={() => setOpenBrochureForm(false)}
                small={small}
                hicity
            />
        </>
    );
};
