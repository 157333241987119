import { useEffect, useState } from "react";
import { endpoints } from "api/endpoints";
import { GET_REQUEST } from "api/api-services";

export const HiCitySpecifications = () => {
    const [specificationsData, setSpecificationsData] = useState(null);

    // const section_data = [
    //     {
    //         title: "SR",
    //         productImage: "images/spec-SR.webp",
    //         productData: [
    //             {
    //                 specification: "Performance",
    //                 specificationData: [
    //                     { key: "Type", value: "Li-ion - LFP" },
    //                     { key: "Battery Capacity", value: "8.9 kWh" },
    //                     { key: "Packaging", value: "Yes" },
    //                 ],
    //             },
    //             {
    //                 specification: "Battery",
    //                 specificationData: [
    //                     { key: "Type", value: "Li-ion - LFP" },
    //                     { key: "Battery Capacity", value: "8.9 kWh" },
    //                     { key: "Packaging", value: "Yes" },
    //                 ],
    //             },
    //             {
    //                 specification: "Charger",
    //                 specificationData: [
    //                     { key: "Type", value: "Li-ion - LFP" },
    //                     { key: "Battery Capacity", value: "8.9 kWh" },
    //                     { key: "Packaging", value: "Yes" },
    //                 ],
    //             },
    //             {
    //                 specification: "Suspension",
    //                 specificationData: [
    //                     { key: "Type", value: "Li-ion - LFP" },
    //                     { key: "Battery Capacity", value: "8.9 kWh" },
    //                     { key: "Packaging", value: "Yes" },
    //                 ],
    //             },
    //             {
    //                 specification: "Drivetrain - Electric",
    //                 specificationData: [
    //                     { key: "Type", value: "Li-ion - LFP" },
    //                     { key: "Battery Capacity", value: "8.9 kWh" },
    //                     { key: "Packaging", value: "Yes" },
    //                 ],
    //             },
    //             {
    //                 specification: "Motor & Transmission",
    //                 specificationData: [
    //                     { key: "Type", value: "Li-ion - LFP" },
    //                     { key: "Battery Capacity", value: "8.9 kWh" },
    //                     { key: "Packaging", value: "Yes" },
    //                 ],
    //             },
    //             {
    //                 specification: "Tyre",
    //                 specificationData: [
    //                     { key: "Type", value: "Li-ion - LFP" },
    //                     { key: "Battery Capacity", value: "8.9 kWh" },
    //                     { key: "Packaging", value: "Yes" },
    //                 ],
    //             },
    //             {
    //                 specification: "Braking system",
    //                 specificationData: [
    //                     { key: "Type", value: "Li-ion - LFP" },
    //                     { key: "Battery Capacity", value: "8.9 kWh" },
    //                     { key: "Packaging", value: "Yes" },
    //                 ],
    //             },
    //             {
    //                 specification: "Hill Hold Assist",
    //                 specificationData: [
    //                     { key: "Type", value: "Li-ion - LFP" },
    //                     { key: "Battery Capacity", value: "8.9 kWh" },
    //                     { key: "Packaging", value: "Yes" },
    //                 ],
    //             },
    //         ],
    //     },
    //     {
    //         title: "TR",
    //         productImage: "images/spec-TR.webp",
    //         productData: [
    //             {
    //                 specification: "Performance 2",
    //                 specificationData: [
    //                     { key: "Type", value: "Li-ion - LFP" },
    //                     { key: "Battery Capacity", value: "8.9 kWh" },
    //                     { key: "Packaging", value: "Yes" },
    //                 ],
    //             },
    //             {
    //                 specification: "Battery",
    //                 specificationData: [
    //                     { key: "Type", value: "Li-ion - LFP" },
    //                     { key: "Battery Capacity", value: "8.9 kWh" },
    //                     { key: "Packaging", value: "Yes" },
    //                 ],
    //             },
    //         ],
    //     },
    // ];

    const [activeProductIndex, setActiveProductIndex] = useState(0);
    const [activeAccordionIndex, setActiveAccordionIndex] = useState(0);

    const handleAccordionToggle = (index) => {
        setActiveAccordionIndex(index);
    };

    const fetchSpecificationsData = async () => {
        try {
            const response = await GET_REQUEST(
                endpoints["specifications_Data"]
            );

            if (response?.status) {
                setSpecificationsData(response?.data);
            } else {
                throw new Error(
                    response?.message ||
                        `HTTP error! status: ${response.status}`
                );
            }
        } catch (error) {
            console.log(error?.message);
        }
    };

    useEffect(() => {
        fetchSpecificationsData();
    }, []);

    return (
        <section className="other_spec_section">
            <div className="custom-container">
                <div className="other_spec_wrap">
                    <div className="other_spec_toggle">
                        {specificationsData?.data[0]?.attributes?.OtherSpecificationSection?.section_data.map(
                            (product, productIndex) => (
                                <button
                                    key={productIndex}
                                    type="button"
                                    className={`theme-btn other_spec_item ${
                                        activeProductIndex === productIndex
                                            ? "active"
                                            : ""
                                    }`}
                                    onClick={() => {
                                        setActiveProductIndex(productIndex);
                                        setActiveAccordionIndex(0);
                                    }}
                                >
                                    {product.title}
                                </button>
                            )
                        )}
                    </div>

                    <div className="row">
                        <div className="col-sm-3 col-12">
                            <div className="other_spec_left">
                                <div className="other_spec_header">
                                    <h4 className="secondary_heading">
                                        Other <span>Specifications</span>
                                    </h4>
                                </div>
                                <div className="other_spec_img_wrap">
                                    <img
                                        className="img-fluid active"
                                        src={
                                            specificationsData?.data[0]
                                                ?.attributes
                                                ?.OtherSpecificationSection
                                                ?.section_data[
                                                activeProductIndex
                                            ]?.productImage
                                        }
                                        alt={
                                            specificationsData?.data[0]
                                                ?.attributes
                                                ?.OtherSpecificationSection
                                                ?.section_data[
                                                activeProductIndex
                                            ]?.title
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-9 col-12">
                            <div className="other_spec_tab_wrap">
                                {specificationsData?.data[0]?.attributes?.OtherSpecificationSection?.section_data[
                                    activeProductIndex
                                ]?.productData?.map((product, index) => (
                                    <div
                                        key={index}
                                        className="other_spec_tab tab_active"
                                        data-id={`other_spec_tab${index + 1}`}
                                    >
                                        <div className="spec_accordion">
                                            <div
                                                className={`spec_accordion_item ${
                                                    activeAccordionIndex ===
                                                    index
                                                        ? "active"
                                                        : ""
                                                }`}
                                            >
                                                <div
                                                    className="accordion_title"
                                                    onClick={() =>
                                                        handleAccordionToggle(
                                                            index
                                                        )
                                                    }
                                                >
                                                    <a
                                                        href="#!"
                                                        onClick={(e) =>
                                                            e.preventDefault()
                                                        }
                                                        className="title"
                                                    >
                                                        {product?.specification}
                                                        <span className="spec_icon">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={39}
                                                                height={40}
                                                                viewBox="0 0 39 40"
                                                                fill="none"
                                                            >
                                                                <rect
                                                                    width={39}
                                                                    height={39}
                                                                    rx="19.5"
                                                                    transform="matrix(1 0 0 -1 0 39.9463)"
                                                                    fill="white"
                                                                />
                                                                <path
                                                                    d="M11.8184 23.583L19.5002 16.4921L27.182 23.583"
                                                                    stroke="#1C1C1C"
                                                                    strokeOpacity="0.7"
                                                                    strokeWidth={
                                                                        2
                                                                    }
                                                                    strokeLinecap="round"
                                                                />
                                                            </svg>
                                                        </span>
                                                    </a>
                                                </div>

                                                {activeAccordionIndex ===
                                                    index && (
                                                    <div className="accordion_content">
                                                        <ul>
                                                            {product?.specificationData?.map(
                                                                (
                                                                    specification,
                                                                    specificationIndex
                                                                ) => (
                                                                    <li
                                                                        key={
                                                                            specificationIndex
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {
                                                                                specification?.key
                                                                            }
                                                                        </span>
                                                                        <span>
                                                                            {
                                                                                specification?.value
                                                                            }
                                                                        </span>
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
