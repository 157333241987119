import Slider from "react-slick";
import { useEffect, useRef, useState } from "react";
//
import { endpoints } from "api/endpoints";
import { GET_REQUEST } from "api/api-services";
import { VideoPopup } from "../video-popup";

export const HiCityTestimonials = () => {
    const sliderRef = useRef(null);

    const [testimonialsData, setTestimonialsData] = useState(null);
    const [showVideo, setShowVideo] = useState(false);

    const sliderOptions = {
        ref: sliderRef,
        dots: true,
        arrows: false,
        infinite: true,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2.2,
                    slidesToScroll: 2,
                    dots: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1.2,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
        ],
    };

    const fetchTestimonialsData = async () => {
        try {
            const response = await GET_REQUEST(endpoints["testimonial_data"]);

            if (response?.status) {
                setTestimonialsData(response?.data);
            } else {
                throw new Error(
                    response?.message ||
                        `HTTP error! status: ${response.status}`
                );
            }
        } catch (error) {
            console.log(error?.message);
        }
    };

    useEffect(() => {
        fetchTestimonialsData();
    }, []);

    return (
        <>
            <section className="testimonial_section bg_secondary">
                <div className="custom-container">
                    <div className="testimonial_header">
                        <h4 className="secondary_heading">
                            Customer <span>Testimonials</span>
                        </h4>
                    </div>
                    <div className="testimonial_body">
                        <Slider
                            {...sliderOptions}
                            className="testimonial_slider"
                        >
                            {testimonialsData?.data?.[0]?.attributes?.CustomerSectionData?.map(
                                (item, index) => (
                                    <div key={index} className="slick-slide">
                                        <div className="testimonial_item">
                                            <div className="testimonial_img_wrap">
                                                <img
                                                    className="img-fluid"
                                                    src={
                                                        item?.CustomerImage
                                                            ?.data?.attributes
                                                            ?.url
                                                    }
                                                    alt={
                                                        item?.CustomerImage
                                                            ?.data?.attributes
                                                            ?.name
                                                    }
                                                />
                                                <a
                                                    href={
                                                        item?.CustomerVideoLink
                                                    }
                                                    className="play_icon"
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        setShowVideo(
                                                            item
                                                                ?.CustomerMediaVideo
                                                                ?.data
                                                                ?.attributes
                                                                ?.url
                                                        );
                                                    }}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={42}
                                                        height={42}
                                                        viewBox="0 0 42 42"
                                                        fill="none"
                                                    >
                                                        <g clipPath="url(#clip0_7_365)">
                                                            <path
                                                                d="M39.9423 6.91535C34.148 6.08134 27.7445 5.61914 21.0129 5.61914C14.2814 5.61914 7.87784 6.08134 2.08362 6.91535C0.973321 11.261 0.356201 16.0637 0.356201 21.1117C0.356201 26.1597 0.973321 30.9624 2.08362 35.308C7.87784 36.1421 14.2814 36.6043 21.0129 36.6043C27.7445 36.6043 34.148 36.1421 39.9423 35.308C41.0526 30.9624 41.6697 26.1597 41.6697 21.1117C41.6697 16.0637 41.0526 11.261 39.9423 6.91535ZM15.8488 28.858V13.3654L28.7592 21.1117L15.8488 28.858Z"
                                                                fill="white"
                                                            />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_7_365">
                                                                <rect
                                                                    width="41.3135"
                                                                    height="41.3135"
                                                                    fill="white"
                                                                    transform="translate(0.356201 0.455078)"
                                                                />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </a>
                                            </div>
                                            <h5 className="testimonial_name">
                                                {item?.CustomerName}
                                            </h5>
                                            {/* <div className="testimonial_position">
                                                {item?.CustomerType}
                                            </div> */}
                                            <div className="testimonial_location">
                                                {item?.CustomerLocation}
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </Slider>

                        <ul className="testimonial_btn">
                            <li className="prev_li">
                                <button
                                    onClick={() =>
                                        sliderRef?.current?.slickPrev()
                                    }
                                    className="testimonial-button-prev"
                                    type="button"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={61}
                                        height={61}
                                        viewBox="0 0 61 61"
                                        fill="none"
                                    >
                                        <rect
                                            width={61}
                                            height={61}
                                            transform="matrix(-1 0 0 1 61 0.619141)"
                                            fill="#F5F5F5"
                                        />
                                        <path
                                            d="M19.4697 32.1495C19.1768 31.8566 19.1768 31.3817 19.4697 31.0888L24.2426 26.3158C24.5355 26.0229 25.0104 26.0229 25.3033 26.3158C25.5962 26.6087 25.5962 27.0836 25.3033 27.3765L21.0607 31.6191L25.3033 35.8618C25.5962 36.1547 25.5962 36.6295 25.3033 36.9224C25.0104 37.2153 24.5355 37.2153 24.2426 36.9224L19.4697 32.1495ZM40 32.3691L20 32.3691L20 30.8691L40 30.8691L40 32.3691Z"
                                            fill="#1C1C1C"
                                        />
                                    </svg>
                                </button>
                            </li>
                            <li className="next_li">
                                <button
                                    onClick={() => {
                                        sliderRef.current.slickNext();
                                        console.log("called");
                                    }}
                                    className="testimonial-button-next"
                                    type="button"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={61}
                                        height={61}
                                        viewBox="0 0 61 61"
                                        fill="none"
                                    >
                                        <rect
                                            width={61}
                                            height={61}
                                            transform="translate(0.196289 0.619141)"
                                            fill="#F5F5F5"
                                        />
                                        <path
                                            d="M41.7266 32.1495C42.0195 31.8566 42.0195 31.3817 41.7266 31.0888L36.9536 26.3158C36.6608 26.0229 36.1859 26.0229 35.893 26.3158C35.6001 26.6087 35.6001 27.0836 35.893 27.3765L40.1356 31.6191L35.893 35.8618C35.6001 36.1547 35.6001 36.6295 35.893 36.9224C36.1859 37.2153 36.6608 37.2153 36.9536 36.9224L41.7266 32.1495ZM21.1963 32.3691L41.1963 32.3691L41.1963 30.8691L21.1963 30.8691L21.1963 32.3691Z"
                                            fill="#1C1C1C"
                                        />
                                    </svg>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <VideoPopup open={showVideo} onClose={() => setShowVideo(false)} />
        </>
    );
};
