import { useEffect, useState } from "react";
import { endpoints } from "api/endpoints";
import { GET_REQUEST } from "api/api-services";

import Modes from "assets/hicity/modes.webp";

export const HiCityPerformanceMetrics = () => {
    const [activeSlide, setActiveSlide] = useState(0);
    const [performanceData, setPerformanceData] = useState(null);

    const fetchPerformanceData = async () => {
        try {
            const response = await GET_REQUEST(endpoints["performance_data"]);
            if (response?.status) {
                setPerformanceData(response?.data);
            } else {
                throw new Error(
                    response?.message ||
                        `HTTP error! status: ${response.status}`
                );
            }
        } catch (error) {
            console.log(error?.message);
        }
    };
    useEffect(() => {
        fetchPerformanceData();
    }, []);

    return (
        <section className="performance_section">
            <div className="custom-container">
                <div className="row">
                    <div className="col-12 col-sm-3">
                        <div className="performance_header">
                            <h3 className="secondary_heading">
                                {
                                    performanceData?.data?.[0]?.attributes
                                        ?.Heading
                                }
                                -
                                <span>
                                    {
                                        performanceData?.data?.[0]?.attributes
                                            ?.SubHeading
                                    }
                                </span>
                            </h3>
                        </div>
                        <ul className="performance_list">
                            {performanceData?.data?.[0]?.attributes?.PerformanceFeatures?.map(
                                (item, index) => (
                                    <li
                                        key={index}
                                        onClick={() => setActiveSlide(index)}
                                    >
                                        <a
                                            className={`performance_list_item ${
                                                index === activeSlide
                                                    ? "active"
                                                    : ""
                                            }`}
                                            href="javascript:void(0);"
                                            data-id="performance_tab1"
                                        >
                                            <span className="list_icon">
                                                <img
                                                    src={
                                                        performanceData
                                                            ?.data?.[0]
                                                            ?.attributes?.[
                                                            `FeatureSectionIcon${
                                                                index + 1
                                                            }`
                                                        ]?.data?.attributes?.url
                                                    }
                                                    alt=""
                                                />
                                            </span>
                                            {item?.FeatureTitle}
                                        </a>
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                    <div className="col-12 col-sm-9">
                        <div className="performance_tab_wrap">
                            <div
                                className={`performance_tab tab_active`}
                                data-id={`performance_tab${activeSlide + 1}`}
                            >
                                <div className="performance_content_wrap">
                                    <div className="shape_1"></div>
                                    <div className="shape_2"></div>
                                    <div className="performance_content">
                                        <h4 className="heading">
                                            {
                                                performanceData?.data?.[0]
                                                    ?.attributes
                                                    ?.PerformanceFeatures?.[
                                                    activeSlide
                                                ]?.FeatureHeading
                                            }
                                        </h4>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: performanceData
                                                    ?.data?.[0]?.attributes
                                                    ?.PerformanceFeatures?.[
                                                    activeSlide
                                                ]?.FeatureDescription,

                                                // section_data[
                                                //     activeSlide
                                                // ]?.description,
                                            }}
                                        ></p>
                                    </div>
                                    <div className="performance_img_wrap">
                                        <img
                                            className="img-fluid"
                                            src={Modes}
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
