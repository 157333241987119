export const Populate = "?populate=*";
export const TestimonialPopulate = "?populate[CustomerSectionData][populate]=*";
export const ComparePopulate =
    "?populate[LandingPageCompareLeftImageDesktop]=*&populate[LandingPageCompareLeftImageMobile]=*&populate[LandingPageCompareRightImageMobile]=*&populate[LandingPageCompareRightImageDesktop]=*&populate[AccordionSection][populate]=*";
export const footerPopulate =
    "?populate[FooterData][populate]=*&populate[MobileFooterData][populate]=*";

export const powerfulCargoPopulate =
    "?populate[PowerfulCargoSectionButtonData][populate]=*&populate[PhoneNumberIcon][populate]=*&populate[PowefulCargoDownloadSectionData][populate]=*&populate[PowefulCargoDownloadSectionData][ButtonIcon][ButtonMedia][populate]=*";

export const endpoints = {
    //Common Header and Footer
    header_data: "landing-page-headers" + Populate,
    footer_data: "landing-page-footers" + footerPopulate,

    //Home Page
    quality_data: "landing-page-quality-assurance-timelines" + Populate,
    banner_data: "landing-page-banner-sections" + Populate,
    powerful_cargo_data:
        "landing-page-powerful-cargo-sections" + powerfulCargoPopulate,
    build_quality_data: "landing-page-build-quality-sections" + Populate,
    faq_data: "landing-page-faq-accordions" + Populate,
    video_data: "landing-page-videos" + Populate,
    technology_highlights_data:
        "landing-page-technology-highlights-sections" + Populate,
    performance_data: "landing-page-performance-metrics" + Populate,
    features_data: "Landing-page-features-section-contents" + Populate,
    compare_section_data: "compare-sections" + Populate,
    assistance_data:
        "Landing-page-roadside-assistance-left-contents" + Populate,
    compare_data: "compare-sections" + ComparePopulate,
    testimonial_data:
        "landing-page-customer-testimonials" + TestimonialPopulate,
    specifications_Data: "landing-page-other-specifications" + Populate,
    CHARGING_NETWORKS: "charging-networks" + Populate,
    CHARGING_NETWORKS_FEATURES: "/api/charging-network-features?populate=*",
    CHARGING_NETWORKS_SECTION: "/api/charging-network-sections?populate=*",
    GLOBAL_CITIES: "/global-list-cities?populate=*",
    GLOBAL_STATES: "/states-lists?populate=*",
    PRODUCTS: "products" + Populate,
    STORE_LOCATION:
        "/store-locations?populate=*&sort[0]=id:asc&pagination[pageSize]=200",
};
