import "./header.css";
//
import { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/system";
//
import { endpoints } from "api/endpoints";
import { GET_REQUEST } from "api/api-services";
import Broucher from "Pages/components/Broucher";

export const HiCityHeader = () => {
    const small = useMediaQuery("(max-width: 786px)");

    const [headerData, setHeaderData] = useState(null);
    const [openBrochureForm, setOpenBrochureForm] = useState(false);

    const fetchHeaderData = async () => {
        try {
            const response = await GET_REQUEST(endpoints["header_data"]);

            if (response?.status) {
                setHeaderData(response?.data);
            } else {
                throw new Error(
                    response?.message ||
                        `HTTP error! status: ${response.status}`
                );
            }
        } catch (error) {
            console.log(error?.message);
        }
    };

    useEffect(() => {
        fetchHeaderData();
    }, []);

    return (
        <>
            <nav className="main_menu bg_secondary">
                <div className="logo_area">
                    <a
                        href={
                            headerData?.data[0].attributes.HeaderLogoLink || "/"
                        }
                    >
                        <img
                            className="img-fluid"
                            src={
                                headerData?.data?.[0]?.attributes?.HeaderLogo
                                    ?.data?.attributes?.url
                            }
                            alt=""
                        />
                    </a>
                </div>
                <div className="d-xs">
                    <a
                        className="theme-btn theme-btn-primary"
                        href={
                            headerData?.data?.[0]?.attributes?.HeaderBookRideUrl
                        }
                        onClick={(event) => {
                            if (
                                !!headerData?.data?.[0]?.attributes
                                    ?.HeaderBookRideMedia?.data
                            ) {
                                event.preventDefault();
                                setOpenBrochureForm(true);
                            }
                        }}
                    >
                        {
                            headerData?.data?.[0]?.attributes
                                ?.HeaderBookRideButton
                        }
                    </a>
                </div>
                <div className="book_now_wrap d-lg">
                    <a
                        className="theme-btn theme-btn-primary"
                        href={
                            headerData?.data?.[0]?.attributes?.HeaderBookRideUrl
                        }
                        onClick={(event) => {
                            if (
                                !!headerData?.data?.[0]?.attributes
                                    ?.HeaderBookRideMedia?.data
                            ) {
                                event.preventDefault();
                                setOpenBrochureForm(true);
                            }
                        }}
                    >
                        {
                            headerData?.data?.[0]?.attributes
                                ?.HeaderBookRideButton
                        }
                    </a>
                    <a
                        className="theme-btn theme-btn-secondary"
                        href={
                            headerData?.data?.[0]?.attributes?.HeaderContactUrl
                        }
                    >
                        <span className="btn_icon">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={13}
                                height={14}
                                viewBox="0 0 13 14"
                                fill="none"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M2.81868 1.55566C2.86093 2.24789 2.96655 2.92455 3.13555 3.57011L2.29055 4.50344C2.00184 3.57011 1.81875 2.58233 1.75538 1.55566H2.81868ZM9.76029 10.9038C10.3588 11.0905 10.9715 11.2071 11.5911 11.2538V12.4127C10.6616 12.3427 9.76733 12.1405 8.91528 11.8294L9.76029 10.9038ZM3.49372 0H1.02912C0.641828 0 0.324951 0.35 0.324951 0.777778C0.324951 8.08111 5.68369 14 12.2958 14C12.6831 14 13 13.65 13 13.2222V10.5078C13 10.08 12.6831 9.73 12.2958 9.73C11.4227 9.73 10.5706 9.57444 9.78196 9.28667C9.71154 9.25556 9.63408 9.24778 9.56366 9.24778C9.38058 9.24778 9.20454 9.32556 9.0637 9.47333L7.51453 11.1844C5.52173 10.0567 3.88805 8.26 2.87405 6.05889L4.42322 4.34778C4.62039 4.13 4.67672 3.82667 4.59926 3.55444C4.33872 2.68333 4.19789 1.75 4.19789 0.777778C4.19789 0.35 3.88101 0 3.49372 0Z"
                                    fill="currentColor"
                                />
                            </svg>
                            {/* <img
                                src={
                                    headerData?.data?.[0]?.attributes
                                        ?.ContactNumberIcon?.data?.attributes
                                        ?.url
                                }
                                alt=""
                            /> */}
                        </span>
                        {headerData?.data?.[0]?.attributes?.HeaderContact}
                    </a>
                </div>
            </nav>

            <Broucher
                open={openBrochureForm}
                handleClose={() => setOpenBrochureForm(false)}
                small={small}
                hicity
            />
        </>
    );
};
