import { Grid, LinearProgress, useMediaQuery } from "@mui/material";
import React, { lazy, useState, Suspense } from "react";
import { downloadBroucher } from "utils";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import ScrollToTop from "ReusableComponents/ScrollToTop";
import ShowroomNetwork from "Pages/ShowroomNetwork/ShowroomNetwork";
import ServicePage from "Pages/Service/Service";
import { useTranslation } from "react-i18next";
import withLanguageRedirect from "./withLanguageRedirect"; // Import the HOC
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { HiCityView } from "Pages/Hicity";

const Footer = lazy(() => import("Pages/Landing/components/Footer/Footer"));
const Header = lazy(() => import("Pages/Landing/components/Header/Header"));

const Landing = lazy(() => import("Pages/Landing"));
const Error404 = lazy(() => import("Pages/ErrorPages/Error404"));

const Careers = lazy(() => import("Pages/Careers/Careers"));
const Telematics = lazy(() => import("Pages/Telematics/Telematics"));
const Privacy = lazy(() => import("Pages/Policy/Pricvacy/Privacy"));
const Terms = lazy(() => import("Pages/Policy/Terms/Terms"));
const Refund = lazy(() => import("Pages/Policy/Refund/Refund"));
const ContactUs = lazy(() => import("Pages/ContactUs/ContactUs"));
const TestDrive = lazy(() => import("Pages/TestDrive/TestDrive"));
const StormEv = lazy(() => import("Pages/StormEv/StormEv"));
const StormEvLr = lazy(() => import("Pages/StormEv-lr/StormEv-lr"));

const TestDriveThankYou = lazy(() =>
    import("Pages/TestDrive/TestDriveThankYou/TestDriveThankyou")
);
const Booking = lazy(() => import("Pages/Booking/Booking"));
const BookingThankYou = lazy(() =>
    import("Pages/Booking/BookingThankYou/BookingThankYou")
);
const Dealership = lazy(() => import("Pages/Dealership/Dealership"));
const AboutUs = lazy(() => import("Pages/AboutUs/AboutUs"));
const Blogs = lazy(() => import("Pages/Blogs/Blogs"));
const ThreeWheeler = lazy(() => import("Pages/3w/ThreeWheeler"));
const Banner = lazy(() => import("Pages/components/Banner"));
const News = lazy(() => import("Pages/News/News"));
const ProductReview = lazy(() => import("Pages/ProductReview/ProductReview"));
const BlogPage = lazy(() => import("Pages/Blogs/components/BlogPage/BlogPage"));
const ChargingNetowrk = lazy(() =>
    import("Pages/ChargingNetwork/ChargingNetowrk")
);

export const hiCityHref = "hicity";

const LinksToAvoidBanner = () => {
    if (
        window.location.href.includes("book") ||
        window.location.href.includes("ride") ||
        window.location.href.includes("terms") ||
        window.location.href.includes("policy") ||
        window.location.href.includes("refund") ||
        window.location.href.includes("blog")
    ) {
        return true;
    }
    return false;
};

const isHicityPage = () => {
    if (window.location.href?.includes(hiCityHref)) {
        return true;
    } else {
        return false;
    }
};

const LanguageRedirect = ({ children }) => {
    const defaultLanguage = "en"; // Set your default language here
    const supportedLanguages = ["en", "gu", "hi", "kn", "mr", "ta", "te", "bn"];

    return (
        <Route
            path="*"
            render={({ location }) => {
                const { pathname } = location;

                if (pathname === "/thank-you-test-drive") {
                    return children;
                }

                const langMatch = pathname.match(
                    /^\/(en|gu|hi|kn|mr|ta|te|bn)(\/|$)/
                );

                if (!langMatch) {
                    return <Redirect to={`/${defaultLanguage}${pathname}`} />;
                }

                return children;
            }}
        />
    );
};

export const NavLinks = () => {
    const [active, setActive] = useState(0);
    const small = useMediaQuery("(max-width: 786px)");
    const medium = useMediaQuery("(max-width: 1024px)");
    const { i18n } = useTranslation();

    const handleActive = (index) => setActive(index);

    const RenderNavLinks = () => {
        return (
            <Suspense fallback={<LinearProgress />}>
                {!isHicityPage() && (
                    <Grid
                        item
                        xs={12}
                        style={{ paddingLeft: 0, position: "relative" }}
                    >
                        <Header active={active} onClick={handleActive} />
                    </Grid>
                )}

                <BrowserRouter>
                    <LanguageRedirect>
                        <Switch>
                            <Route
                                exact
                                path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/"
                                component={withLanguageRedirect(Landing)}
                            />
                            <Route
                                exact
                                path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/blogs"
                                component={withLanguageRedirect(Blogs)}
                            />{" "}
                            <Route
                                exact
                                path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/storm-ev/lr"
                                component={withLanguageRedirect(StormEvLr)}
                            />
                            <Route
                                exact
                                path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/hiload"
                                component={withLanguageRedirect(ThreeWheeler)}
                            />
                            <Route
                                exact
                                path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/about-us"
                                component={withLanguageRedirect(AboutUs)}
                            />
                            <Route
                                exact
                                path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/charging-network"
                                component={withLanguageRedirect(
                                    ChargingNetowrk
                                )}
                            />
                            <Route
                                exact
                                path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/vehicle-intelligence"
                                component={withLanguageRedirect(Telematics)}
                            />
                            <Route
                                exact
                                path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/policy"
                                component={withLanguageRedirect(Privacy)}
                            />
                            <Route
                                exact
                                path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/terms"
                                component={withLanguageRedirect(Terms)}
                            />
                            <Route
                                exact
                                path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/contact-us"
                                component={withLanguageRedirect(ContactUs)}
                            />
                            <Route
                                exact
                                path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/refund"
                                component={withLanguageRedirect(Refund)}
                            />
                            <Route
                                exact
                                path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/test-ride"
                                component={withLanguageRedirect(TestDrive)}
                            />
                            <Route
                                exact
                                path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/storm-ev"
                                component={withLanguageRedirect(StormEv)}
                            />
                            <Route
                                exact
                                path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/book"
                                component={withLanguageRedirect(Booking)}
                            />
                            <Route
                                exact
                                path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/dealership"
                                component={withLanguageRedirect(Dealership)}
                            />
                            <Route
                                exact
                                path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/blogs/:id"
                                component={withLanguageRedirect(BlogPage)}
                            />
                            <Route
                                exact
                                path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/news"
                                component={withLanguageRedirect(News)}
                            />
                            <Route
                                exact
                                path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/product-reviews"
                                component={withLanguageRedirect(ProductReview)}
                            />
                            <Route
                                exact
                                path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/career"
                                component={withLanguageRedirect(Careers)}
                            />
                            <Route
                                exact
                                path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/service-network"
                                component={withLanguageRedirect(ServicePage)}
                            />
                            <Route
                                exact
                                path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/showroom-locator"
                                component={withLanguageRedirect(
                                    ShowroomNetwork
                                )}
                            />
                            <Route
                                exact
                                path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/thank-you-test-drive"
                                component={withLanguageRedirect(
                                    TestDriveThankYou
                                )}
                            />
                            <Route
                                exact
                                path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/products/hiload"
                                component={() => <Redirect to="/hiload" />}
                            />
                            <Route
                                exact
                                path="/:lang(en|gu|hi|kn|mr|ta|te|bn)/thank-you-booking"
                                component={BookingThankYou}
                            />
                            <Route
                                exact
                                path={`/:lang(en|gu|hi|kn|mr|ta|te|bn)/${hiCityHref}`}
                                component={HiCityView}
                            />
                            <Route
                                exact
                                path="/*"
                                component={withLanguageRedirect(Error404)}
                            />
                        </Switch>
                    </LanguageRedirect>
                </BrowserRouter>

                {!LinksToAvoidBanner() && !isHicityPage() && (
                    <Grid
                        item
                        xs={12}
                        style={{
                            paddingTop: 0,
                            background: "#9CCDFB",
                            paddingLeft: 0,
                        }}
                    >
                        <Banner
                            downloadBroucher={downloadBroucher}
                            small={small}
                            medium={medium}
                        />
                    </Grid>
                )}

                <ScrollToTop />

                {!isHicityPage() && (
                    <Grid item xs={12}>
                        <Footer />
                    </Grid>
                )}
            </Suspense>
        );
    };

    return isHicityPage() ? (
        <RenderNavLinks />
    ) : (
        <Grid container spacing={2} sx={{ position: "relative" }}>
            <RenderNavLinks />
        </Grid>
    );
};
