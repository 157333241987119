import "./style.css";
//
import { useEffect, useState } from "react";
//
import { endpoints } from "api/endpoints";
import { GET_REQUEST } from "api/api-services";
//
import {
    HiCityBuildQuality,
    HiCityBannerSection,
    HiCityTechnologyHighlights,
    HiCityPerformanceMetrics,
    HiCityFeatures,
    HiCityPowerful,
    HiCityCompare,
    HiCitySpecifications,
    HiCityQuality,
    HiCityBrandVideo,
    HiCityAssistance,
    HiCityEnquiry,
    HiCityOurNetwork,
    HiCityTestimonials,
    HiCityFAQ,
} from "./sections";
import { HiCityHeader } from "./header";
import { HiCityFooter } from "./footer";

export const HiCityView = () => {
    const [states, setStates] = useState([]);
    const [cities, setCitites] = useState([]);
    const [products, setProducts] = useState([]);
    const [storeData, setStoreData] = useState([]);

    const getStatesData = async () => {
        const response = await GET_REQUEST(endpoints["GLOBAL_STATES"]);

        if (response?.status) {
            setStates(response?.data?.data);
        }
    };

    const getCitiesData = async () => {
        const response = await GET_REQUEST(endpoints["GLOBAL_CITIES"]);

        if (response?.status) {
            setCitites(response?.data?.data);
        }
    };

    const getProductData = async () => {
        const response = await GET_REQUEST(endpoints["PRODUCTS"]);

        if (response?.status) {
            setProducts(response?.data?.data);
        }
    };

    const getStoreData = async () => {
        const response = await GET_REQUEST(endpoints["STORE_LOCATION"]);

        if (response?.status) {
            setStoreData(response?.data?.data);
        }
    };

    const intialize = async () => {
        await getCitiesData();
        await getStatesData();
        await getProductData();
        await getStoreData();
    };

    useEffect(() => {
        intialize();
    }, []);

    return (
        <>
            <main className="main">
                <div className="main_wrapper">
                    <HiCityHeader />

                    <HiCityBannerSection />
                    <HiCityBuildQuality />
                    <HiCityTechnologyHighlights />
                    <HiCityPerformanceMetrics />
                    <HiCityFeatures />
                    <HiCityPowerful />
                    <HiCityCompare />
                    <HiCitySpecifications />
                    <HiCityQuality />
                    <HiCityBrandVideo />
                    <HiCityAssistance />
                    <HiCityEnquiry
                        states={states}
                        cities={cities}
                        products={products}
                    />
                    <HiCityOurNetwork
                        storeData={storeData}
                        states={states}
                        cities={cities}
                    />
                    <HiCityTestimonials />
                    <HiCityFAQ />

                    <HiCityFooter />
                </div>
            </main>
        </>
    );
};
