import { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/system";
//
import { endpoints } from "api/endpoints";
import { GET_REQUEST } from "api/api-services";
//
import BuildQuality from "assets/hicity/build-quality.webp";

export const HiCityBuildQuality = () => {
    const small = useMediaQuery("(max-width: 768px)");

    const [buildQualityData, setBuildQualityData] = useState(null);

    const svgData = {
        svg_1: (
            <svg
                width={615}
                height={171}
                viewBox="0 0 615 171"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <line
                    x1={692}
                    y1="0.696614"
                    y2="0.696614"
                    stroke="#686868"
                    strokeWidth="1.39323"
                />
                <line
                    x1="690.606"
                    y1="-50.077"
                    x2="690.606"
                    y2="0.21564"
                    stroke="#686868"
                    strokeWidth="1.39323"
                />
                <circle
                    cx="690.607"
                    cy="-50.347"
                    r="11.597"
                    fill="#fff"
                    fillOpacity="0.7"
                >
                    <animate
                        attributeName="r"
                        from={8}
                        to={30}
                        dur="2s"
                        begin="0s"
                        repeatCount="indefinite"
                    />
                    <animate
                        attributeName="opacity"
                        from={1}
                        to={0}
                        dur="2s"
                        begin="0s"
                        repeatCount="indefinite"
                    />
                </circle>
                <circle
                    cx="690.607"
                    cy="-50.347"
                    r="11.597"
                    fill="#356998"
                    stroke="#fff"
                    strokeWidth="1.39323"
                />
            </svg>
        ),
        svg_2: (
            <svg
                width={816}
                height={182}
                viewBox="0 0 816 182"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <line
                    x1="805.331"
                    y1="181.073"
                    x2="0.0456543"
                    y2="181.073"
                    stroke="#686868"
                    strokeWidth="1.39323"
                />
                <line
                    x1="804.634"
                    y1="180.376"
                    x2="804.634"
                    y2="18.7621"
                    stroke="#686868"
                    strokeWidth="1.39323"
                />
                <circle
                    cx="804.104"
                    cy="12.2337"
                    r="11.597"
                    fill="#fff"
                    fillOpacity="0.7"
                >
                    <animate
                        attributeName="r"
                        from={8}
                        to={30}
                        dur="2s"
                        begin="0s"
                        repeatCount="indefinite"
                    />
                    <animate
                        attributeName="opacity"
                        from={1}
                        to={0}
                        dur="2s"
                        begin="0s"
                        repeatCount="indefinite"
                    />
                </circle>
                <circle
                    cx="804.104"
                    cy="12.2337"
                    r="11.597"
                    fill="#356998"
                    stroke="#fff"
                    strokeWidth="1.39323"
                />
            </svg>
        ),
        svg_3: (
            <svg
                width={754}
                height={178}
                viewBox="0 0 754 178"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <line
                    y1="-0.696614"
                    x2="741.197"
                    y2="-0.696614"
                    transform="matrix(1 0 0 -1 12.2739 175.793)"
                    stroke="#686868"
                    strokeWidth="1.39323"
                />
                <line
                    y1="-0.696614"
                    x2="161.614"
                    y2="-0.696614"
                    transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 12.2739 175.793)"
                    stroke="#686868"
                    strokeWidth="1.39323"
                />
                <circle
                    cx="12.5369"
                    cy="11.6561"
                    r="11.597"
                    fill="#fff"
                    fillOpacity="0.7"
                >
                    <animate
                        attributeName="r"
                        from={8}
                        to={30}
                        dur="2s"
                        begin="0s"
                        repeatCount="indefinite"
                    />
                    <animate
                        attributeName="opacity"
                        from={1}
                        to={0}
                        dur="2s"
                        begin="0s"
                        repeatCount="indefinite"
                    />
                </circle>
                <circle
                    cx="12.5369"
                    cy="11.6561"
                    r="11.597"
                    fill="#356998"
                    stroke="#fff"
                    strokeWidth="1.39323"
                />
            </svg>
        ),
        svg_5: (
            <svg
                width={612}
                height={24}
                viewBox="0 0 612 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <line
                    x1="13.9897"
                    y1="12.5555"
                    x2="611.684"
                    y2="12.5555"
                    stroke="#686868"
                    strokeWidth="1.39323"
                />
                <circle
                    cx="12.095"
                    cy="12.3653"
                    r="11.597"
                    fill="#fff"
                    fillOpacity="0.7"
                >
                    <animate
                        attributeName="r"
                        from={8}
                        to={30}
                        dur="2s"
                        begin="0s"
                        repeatCount="indefinite"
                    />
                    <animate
                        attributeName="opacity"
                        from={1}
                        to={0}
                        dur="2s"
                        begin="0s"
                        repeatCount="indefinite"
                    />
                </circle>
                <circle
                    cx="12.095"
                    cy="12.3653"
                    r="11.597"
                    fill="#356998"
                    stroke="#fff"
                    strokeWidth="1.39323"
                />
            </svg>
        ),
        svg_4: (
            <svg
                width={420}
                height={24}
                viewBox="0 0 420 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <line
                    x1="14.4067"
                    y1="11.1803"
                    x2="419.836"
                    y2="11.1804"
                    stroke="#686868"
                    strokeWidth="1.39323"
                />
                <circle
                    cx="12.5584"
                    cy="12.2638"
                    r="11.597"
                    fill="#fff"
                    fillOpacity="0.7"
                >
                    <animate
                        attributeName="r"
                        from={8}
                        to={30}
                        dur="2s"
                        begin="0s"
                        repeatCount="indefinite"
                    />
                    <animate
                        attributeName="opacity"
                        from={1}
                        to={0}
                        dur="2s"
                        begin="0s"
                        repeatCount="indefinite"
                    />
                </circle>
                <circle
                    cx="12.5584"
                    cy="12.2638"
                    r="11.597"
                    fill="#356998"
                    stroke="#fff"
                    strokeWidth="1.39323"
                />
            </svg>
        ),
    };

    const svgDataMobile = {
        svg_1: (
            <svg
                width={97}
                height={102}
                viewBox="0 0 97 102"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <line
                    x1="102.25"
                    y1="-50"
                    x2="102.25"
                    y2="101"
                    stroke="#1C1C1C"
                    stroke-opacity="0.45"
                    stroke-width="0.5"
                />
                <line
                    x1="102.5"
                    y1="101.25"
                    x2="-2.18557e-08"
                    y2="101.25"
                    stroke="#1C1C1C"
                    stroke-opacity="0.45"
                    stroke-width="0.5"
                />
                <circle
                    cx="102.5"
                    cy="-55.5"
                    r="7.5"
                    fill="#fff"
                    fillOpacity="0.5"
                >
                    <animate
                        attributeName="r"
                        from={8}
                        to={20}
                        dur="2s"
                        begin="0s"
                        repeatCount="indefinite"
                    />
                    <animate
                        attributeName="opacity"
                        from={1}
                        to={0}
                        dur="2s"
                        begin="0s"
                        repeatCount="indefinite"
                    />
                </circle>
                <circle
                    cx="102.5"
                    cy="-55.5"
                    r="7.5"
                    fill="#356998"
                    stroke="#fff"
                    strokeWidth="0.39323"
                />
            </svg>
        ),
        svg_2: (
            <svg
                width={193}
                height={25}
                viewBox="0 0 193 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <line
                    x1="185"
                    y1="0.25"
                    y2="0.25"
                    stroke="#1C1C1C"
                    stroke-opacity="0.45"
                    stroke-width="0.5"
                />
                <line
                    x1="184.75"
                    y1="20"
                    x2="184.75"
                    y2="-1.09279e-08"
                    stroke="#1C1C1C"
                    stroke-opacity="0.45"
                    stroke-width="0.5"
                />
                <circle
                    cx="185.5"
                    cy="17.5"
                    r="7.5"
                    transform="rotate(-90 185.5 17.5)"
                    fill="#fff"
                    fillOpacity="0.5"
                >
                    <animate
                        attributeName="r"
                        from={8}
                        to={20}
                        dur="2s"
                        begin="0s"
                        repeatCount="indefinite"
                    />
                    <animate
                        attributeName="opacity"
                        from={1}
                        to={0}
                        dur="2s"
                        begin="0s"
                        repeatCount="indefinite"
                    />
                </circle>
                <circle
                    cx="185.5"
                    cy="17.5"
                    r="7.5"
                    transform="rotate(-90 185.5 17.5)"
                    fill="#356998"
                    stroke="#fff"
                    strokeWidth="0.39323"
                />
            </svg>
        ),
        svg_3: (
            <svg
                width={89}
                height={129}
                viewBox="0 0 89 129"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <line
                    x1="7.25"
                    y1="9"
                    x2="7.25001"
                    y2="128"
                    stroke="#1C1C1C"
                    stroke-opacity="0.45"
                    stroke-width="0.5"
                />
                <line
                    x1="89"
                    y1="128.25"
                    x2="7"
                    y2="128.25"
                    stroke="#1C1C1C"
                    stroke-opacity="0.45"
                    stroke-width="0.5"
                />
                <circle
                    cx="7.5"
                    cy="7.5"
                    r="7.5"
                    transform="rotate(-90 7.5 7.5)"
                    fill="#fff"
                    fillOpacity="0.5"
                >
                    <animate
                        attributeName="r"
                        from={8}
                        to={20}
                        dur="2s"
                        begin="0s"
                        repeatCount="indefinite"
                    />
                    <animate
                        attributeName="opacity"
                        from={1}
                        to={0}
                        dur="2s"
                        begin="0s"
                        repeatCount="indefinite"
                    />
                </circle>
                <circle
                    cx="7.5"
                    cy="7.5"
                    r="7.5"
                    transform="rotate(-90 7.5 7.5)"
                    fill="#356998"
                    stroke="#fff"
                    strokeWidth="0.39323"
                />
            </svg>
        ),
        svg_4: (
            <svg
                width={205}
                height={163}
                viewBox="0 0 205 163"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <line
                    x1="197.25"
                    y1="-1.09278e-08"
                    x2="197.25"
                    y2="161"
                    stroke="#1C1C1C"
                    stroke-opacity="0.45"
                    stroke-width="0.5"
                />
                <line
                    x1="197"
                    y1="0.25"
                    y2="0.25"
                    stroke="#1C1C1C"
                    stroke-opacity="0.45"
                    stroke-width="0.5"
                />
                <circle
                    cx="197.5"
                    cy="155.5"
                    r="7.5"
                    transform="rotate(-90 197.5 155.5)"
                    fill="#fff"
                    fillOpacity="0.5"
                >
                    <animate
                        attributeName="r"
                        from={8}
                        to={20}
                        dur="2s"
                        begin="0s"
                        repeatCount="indefinite"
                    />
                    <animate
                        attributeName="opacity"
                        from={1}
                        to={0}
                        dur="2s"
                        begin="0s"
                        repeatCount="indefinite"
                    />
                </circle>
                <circle
                    cx="197.5"
                    cy="155.5"
                    r="7.5"
                    transform="rotate(-90 197.5 155.5)"
                    fill="#356998"
                    stroke="#fff"
                    strokeWidth="0.39323"
                />
            </svg>
        ),
        svg_5: (
            <svg
                width={97}
                height={115}
                viewBox="0 0 97 115"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <line
                    x1="7.25"
                    y1="-1.09278e-08"
                    x2="7.25"
                    y2="113"
                    stroke="#1C1C1C"
                    stroke-opacity="0.45"
                    stroke-width="0.5"
                />
                <line
                    x1="97"
                    y1="0.25"
                    x2="7"
                    y2="0.249992"
                    stroke="#1C1C1C"
                    stroke-opacity="0.45"
                    stroke-width="0.5"
                />
                <circle
                    cx="7.5"
                    cy="107.5"
                    r="7.5"
                    transform="rotate(-90 7.5 107.5)"
                    fill="#fff"
                    fillOpacity="0.5"
                >
                    <animate
                        attributeName="r"
                        from={8}
                        to={20}
                        dur="2s"
                        begin="0s"
                        repeatCount="indefinite"
                    />
                    <animate
                        attributeName="opacity"
                        from={1}
                        to={0}
                        dur="2s"
                        begin="0s"
                        repeatCount="indefinite"
                    />
                </circle>
                <circle
                    cx="7.5"
                    cy="107.5"
                    r="7.5"
                    transform="rotate(-90 7.5 107.5)"
                    fill="#356998"
                    stroke="#fff"
                    strokeWidth="0.39323"
                />
            </svg>
        ),
    };

    const fetchBuildQualityData = async () => {
        try {
            const response = await GET_REQUEST(endpoints["build_quality_data"]);

            if (response?.status) {
                setBuildQualityData(response?.data);
            } else {
                throw new Error(
                    response?.message ||
                        `HTTP error! status: ${response.status}`
                );
            }
        } catch (err) {
            console.error(err);
        }
    };
    useEffect(() => {
        fetchBuildQualityData();
    }, []);

    return (
        <section className="build_quality_section">
            <div className="custom-container">
                <div className="build_quality_header">
                    <h2
                        className="secondary_heading"
                        dangerouslySetInnerHTML={{
                            __html: buildQualityData?.data?.[0]?.attributes
                                ?.BuildQualitySectionHeading,
                        }}
                    />
                </div>
                <div className="build_quality_img_wrap">
                    <div className="">
                        {buildQualityData?.data?.[0]?.attributes?.BuildQuality?.map(
                            (item, index) =>
                                !!item?.title && (
                                    <div
                                        className={`spot spot_${index + 1}`}
                                        key={index}
                                    >
                                        <div
                                            className="spot_text"
                                            dangerouslySetInnerHTML={{
                                                __html: item?.title,
                                            }}
                                        />
                                        <div className="spot_circle">
                                            {small
                                                ? svgDataMobile[
                                                      `svg_${index + 1}`
                                                  ]
                                                : svgData[`svg_${index + 1}`]}
                                        </div>
                                    </div>
                                )
                        )}
                    </div>

                    <div className="text-center">
                        <img className="img-fluid" src={BuildQuality} alt="" />
                    </div>
                    {/* <div className="d-xs">
                        <img className="img-fluid" src={VehicleXS} alt="" />
                    </div> */}
                </div>
            </div>
        </section>
    );
};
