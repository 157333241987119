import { useEffect, useState } from "react";
import { endpoints } from "api/endpoints";
import { GET_REQUEST } from "api/api-services";

export const HiCityFAQ = () => {
    const [activeSlide, setActiveSlide] = useState(0);
    const [faqData, setFaqData] = useState(null);

    const fetchFaqData = async () => {
        try {
            const response = await GET_REQUEST(endpoints["faq_data"]);
            if (response?.status) {
                setFaqData(response?.data);
            } else {
                throw new Error(
                    response?.message ||
                        `HTTP error! status: ${response.status}`
                );
            }
        } catch (error) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        fetchFaqData();
    }, []);

    return (
        <section className="faq_section">
            <div className="custom-container">
                <div className="faq_header">
                    <h4
                        className="secondary_heading"
                        dangerouslySetInnerHTML={{
                            __html:
                                faqData?.data?.[0]?.attributes?.FaqHeading ||
                                "Frequently Asked <span>Questions</span>",
                        }}
                    />
                </div>
                <div className="faq_accordion">
                    {faqData?.data?.[0]?.attributes?.FaqAccordion?.map(
                        (item, index) => (
                            <div
                                key={index}
                                onClick={() => setActiveSlide(index)}
                                className={`accordion_item ${
                                    activeSlide === index ? "active" : ""
                                }`}
                            >
                                <div className="accordion_title">
                                    <a
                                        href="javascript:void(0);"
                                        className="title"
                                    >
                                        {item?.Question}
                                        <span className="faq_icon">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={39}
                                                height={40}
                                                viewBox="0 0 39 40"
                                                fill="none"
                                            >
                                                <rect
                                                    width={39}
                                                    height={39}
                                                    rx="19.5"
                                                    transform="matrix(1 0 0 -1 0 39.9463)"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M11.8184 23.583L19.5002 16.4921L27.182 23.583"
                                                    stroke="#1C1C1C"
                                                    strokeOpacity="0.7"
                                                    strokeWidth={2}
                                                    strokeLinecap="round"
                                                />
                                            </svg>
                                        </span>
                                    </a>
                                </div>
                                <div className="accordion_content">
                                    <p>{item?.Answer}</p>
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>
        </section>
    );
};
