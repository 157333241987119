import { useEffect, useState } from "react";
import { endpoints } from "api/endpoints";
import { GET_REQUEST } from "api/api-services";

export const HiCityCompare = () => {
    const [activeSlide, setActiveSlide] = useState(0);
    const [compareData, setCompareData] = useState(null);

    const featchCompareData = async () => {
        try {
            const response = await GET_REQUEST(endpoints["compare_data"]);
            if (response?.status) {
                setCompareData(response?.data);
            } else {
                throw new Error(
                    response?.message ||
                        `HTTP error! status: ${response.status}`
                );
            }
        } catch (err) {
            console.log(err.message);
        }
    };

    useEffect(() => {
        featchCompareData();
    }, []);

    console.log(compareData);

    return (
        <section className="compare_section bg_secondary">
            <div className="custom-container">
                <div className="compare_header">
                    <h4 className="secondary_heading">
                        {
                            compareData?.data?.[0]?.attributes
                                ?.LandingPageCompareHeading
                        }
                        <br />
                        <span>
                            {
                                compareData?.data?.[0]?.attributes
                                    ?.LandingPageCompareSubHeading
                            }
                        </span>
                    </h4>
                </div>
                <div className="compare_body">
                    <div className="compary_body_img_wrap">
                        <div className="_left">
                            <div className="sr_heading">
                                {
                                    compareData?.data?.[0]?.attributes
                                        ?.LandingPageCompareLeftText
                                }
                            </div>
                            <div className="d-lg">
                                <img
                                    className="img-fluid"
                                    src={
                                        compareData?.data?.[0]?.attributes
                                            ?.LandingPageCompareLeftImageDesktop
                                            ?.data?.attributes?.url
                                    }
                                    alt=""
                                />
                            </div>
                            <div className="d-xs">
                                <img
                                    className="img-fluid"
                                    src={
                                        compareData?.data?.[0]?.attributes
                                            ?.LandingPageCompareLeftImageMobile
                                            ?.data?.attributes?.url
                                    }
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="_right">
                            <div className="sr_heading">
                                {
                                    compareData?.data?.[0]?.attributes
                                        ?.LandingPageCompareRightText
                                }
                            </div>
                            <div className="d-lg">
                                <img
                                    className="img-fluid"
                                    src={
                                        compareData?.data?.[0]?.attributes
                                            ?.LandingPageCompareRightImageDesktop
                                            ?.data?.attributes?.url
                                    }
                                    alt=""
                                />
                            </div>
                            <div className="d-xs">
                                <img
                                    className="img-fluid"
                                    src={
                                        compareData?.data?.[0]?.attributes
                                            ?.LandingPageCompareRightImageMobile
                                            ?.data?.attributes?.url
                                    }
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                    <div className="compare_tab_wrap">
                        <div className="compare_accordion">
                            {compareData?.data?.[0]?.attributes?.AccordionSection?.map(
                                (item, index) => (
                                    <div
                                        key={index}
                                        className={`compare_accordion_item ${
                                            activeSlide === index
                                                ? "active"
                                                : ""
                                        }`}
                                        onClick={() => setActiveSlide(index)}
                                    >
                                        <div
                                            className={`accordion_title ${
                                                activeSlide === index
                                                    ? "active"
                                                    : ""
                                            }`}
                                        >
                                            <a
                                                href="javascript:void(0);"
                                                className="title"
                                            >
                                                {item?.Title}
                                                <span className="compare_icon">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={39}
                                                        height={40}
                                                        viewBox="0 0 39 40"
                                                        fill="none"
                                                    >
                                                        <rect
                                                            width={39}
                                                            height={39}
                                                            rx="19.5"
                                                            transform="matrix(1 0 0 -1 0 39.9463)"
                                                            fill="white"
                                                        />
                                                        <path
                                                            d="M11.8184 23.583L19.5002 16.4921L27.182 23.583"
                                                            stroke="#1C1C1C"
                                                            strokeOpacity="0.7"
                                                            strokeWidth={2}
                                                            strokeLinecap="round"
                                                        />
                                                    </svg>
                                                </span>
                                            </a>
                                        </div>
                                        <div
                                            className={`accordion_content ${
                                                activeSlide === index
                                                    ? "active"
                                                    : ""
                                            }`}
                                            style={{
                                                display:
                                                    activeSlide === index
                                                        ? "block"
                                                        : "none",
                                            }}
                                        >
                                            <div className="accordion_content_item">
                                                <div className="_left">
                                                    <ul>
                                                        {item?.first_variant?.map(
                                                            (
                                                                variant,
                                                                variant_index
                                                            ) => (
                                                                <li
                                                                    key={
                                                                        variant_index
                                                                    }
                                                                >
                                                                    {
                                                                        variant?.title
                                                                    }
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </div>
                                                <div className="seperator" />
                                                <div className="_right">
                                                    <ul>
                                                        {item?.second_variant?.map(
                                                            (
                                                                variant,
                                                                variant_index
                                                            ) => (
                                                                <li
                                                                    key={
                                                                        variant_index
                                                                    }
                                                                >
                                                                    {
                                                                        variant?.title
                                                                    }
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
