import "./footer.css";
//
import { useEffect, useState } from "react";
//
import { endpoints } from "api/endpoints";
import { GET_REQUEST } from "api/api-services";
import Broucher from "Pages/components/Broucher";

export const HiCityFooter = () => {
    const [footerData, setFooterData] = useState(null);
    const [openBrochureForm, setOpenBrochureForm] = useState(false);
    const [small, isSmall] = useState(true);

    const fetchFooterData = async () => {
        try {
            const response = await GET_REQUEST(endpoints["footer_data"]);

            if (response?.status) {
                setFooterData(response?.data);
            } else {
                throw new Error(
                    response?.message ||
                        `HTTP error! status: ${response.status}`
                );
            }
        } catch (error) {
            console.log(error?.message);
        }
    };

    useEffect(() => {
        fetchFooterData();
    }, []);

    return (
        <>
            {/* Desktop Footer */}
            <footer className="footer bg_primary">
                <div className="custom-container">
                    <ul className="footer_list">
                        {footerData?.data?.[0]?.attributes?.FooterData?.map(
                            (item, index) => (
                                <li key={index}>
                                    <a
                                        href={
                                            item?.ButtonMedia?.data?.[0]
                                                ?.attributes?.url ||
                                            item?.ButtonLink
                                        }
                                        download={!!item?.ButtonMedia?.data}
                                        target={
                                            !!item?.ButtonMedia?.data
                                                ? "_blank"
                                                : ""
                                        }
                                        onClick={(event) => {
                                            if (!!item?.ButtonMedia?.data) {
                                                event.preventDefault();
                                                isSmall(false);
                                                setOpenBrochureForm(true);
                                            }
                                        }}
                                    >
                                        <span className="list_icon">
                                            <img
                                                src={
                                                    item?.ButtonIcon?.data
                                                        ?.attributes?.url
                                                }
                                                alt=""
                                            />
                                        </span>
                                        {item?.ButtonText}
                                    </a>
                                </li>
                            )
                        )}
                    </ul>
                </div>
            </footer>

            {/* Mobile Footer */}
            <div className="fixed_mobile_menu">
                <ul className="menu">
                    {footerData?.data?.[0]?.attributes?.MobileFooterData?.map(
                        (item, index) => (
                            <li key={index}>
                                <a
                                    href={
                                        item?.MobileButtonMedia?.data?.[0]
                                            ?.attributes?.url ||
                                        item?.MobileButtonLink
                                    }
                                    target={
                                        !!item?.MobileButtonMedia?.data
                                            ? "_blank"
                                            : ""
                                    }
                                    onClick={(event) => {
                                        if (!!item?.MobileButtonMedia?.data) {
                                            event.preventDefault();
                                            isSmall(true);
                                            setOpenBrochureForm(true);
                                        }
                                    }}
                                >
                                    <span className="list_icon">
                                        <img
                                            src={
                                                item?.MobileButtonIcon?.data
                                                    ?.attributes?.url
                                            }
                                            alt=""
                                            className={
                                                item?.MobileButtonIcon?.data
                                                    ?.attributes?.mime ===
                                                "image/gif"
                                                    ? "img-fluid"
                                                    : ""
                                            }
                                        />
                                    </span>
                                    <span className="text">
                                        {item?.MobileButtonText}
                                    </span>
                                </a>
                            </li>
                        )
                    )}
                </ul>
            </div>

            <Broucher
                open={openBrochureForm}
                handleClose={() => setOpenBrochureForm(false)}
                small={small}
                hicity
            />
        </>
    );
};
