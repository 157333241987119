import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
//
import { apiClient } from "api/apiClient";

export const HiCityEnquiry = ({ states, cities, products }) => {
    const [cityOptions, setCityOptions] = useState([]);

    const formSchema = yup.object().shape({
        name: yup.string().required("Name is required"),
        product: yup.string().required("Product is required"),
        city: yup.string().required("City is required"),
        contact: yup.string().max(10).required("Contact is required"),
        state: yup.string().required("State is required"),
    });

    const methods = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            name: "",
            product: "",
            city: "",
            contact: "",
            state: "",
        },
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = methods;

    const state = {
        city_name: methods["watch"]("city"),
        state_name: methods["watch"]("state"),
    };

    const onSubmit = async (data) => {
        const payload = {
            variant: data?.product,
            name: data?.name,
            city: data?.city,
            state: data?.state,
            phone: data?.contact,
        };

        await apiClient
            .submitContactForm(payload)
            .then((res) => {
                methods["reset"]();
            })
            .finally(() => {});
    };

    const filterChargingHubdsData = () => {
        if (state?.state_name) {
            const filterCities = cities?.filter(
                (item) =>
                    state?.state_name ===
                    item?.attributes?.linked_state?.data?.attributes?.state_name
            );

            setCityOptions(filterCities);
        }
    };

    useEffect(() => {
        filterChargingHubdsData();
    }, []);

    useEffect(() => {
        methods["setValue"]("city", "");
        filterChargingHubdsData();
    }, [state?.state_name]);

    return (
        <section className="enquiry_section bg_secondary">
            <div className="custom-container">
                <div className="enquiry_header">
                    <h4 className="secondary_heading">
                        Enquire <span>Now</span>
                    </h4>
                    <p>Fill the form to get a callback</p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="enquiry_form">
                        <div className="form-group">
                            <input
                                type="text"
                                className={`form-control ${
                                    errors["name"] ? "error" : ""
                                }`}
                                placeholder="Enter Name*"
                                {...register("name")}
                            />
                        </div>

                        <div className="form-group">
                            <input
                                type="text"
                                className={`form-control ${
                                    errors["contact"] ? "error" : ""
                                }`}
                                placeholder="Enter Phone Number*"
                                {...register("contact")}
                            />
                        </div>

                        <div className="form-group">
                            <select
                                className={`form-control ${
                                    errors["product"] ? "error" : ""
                                }`}
                                {...register("product")}
                                // onChange={handleChange}
                            >
                                <option value="">Select Product</option>
                                {products?.map((option, index) => (
                                    <option
                                        value={option?.attributes?.title}
                                        key={option?.id}
                                    >
                                        {option?.attributes?.title}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="form-group">
                            <select
                                className={`form-control ${
                                    errors["state"] ? "error" : ""
                                }`}
                                {...register("state")}
                            >
                                <option value="">Select State</option>
                                {states?.map((option, index) => (
                                    <option
                                        value={option?.attributes?.state_name}
                                        key={option?.id}
                                    >
                                        {option?.attributes?.state_name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="form-group">
                            <select
                                className={`form-control ${
                                    errors["city"] ? "error" : ""
                                }`}
                                {...register("city")}
                                // onChange={handleChange}
                            >
                                <option value="">Select City</option>

                                {state?.state_name && (
                                    <>
                                        {cityOptions?.map((item) => (
                                            <option
                                                key={item.id}
                                                value={
                                                    item?.attributes?.city_name
                                                }
                                            >
                                                {item?.attributes?.city_name}
                                            </option>
                                        ))}
                                    </>
                                )}
                            </select>
                        </div>

                        <div className="form-group date_picker">
                            <button
                                type="submit"
                                className="form-submit form-control"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
};
