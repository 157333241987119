import { useEffect, useRef, useState } from "react";
import Arrow from "../../assets/arrowDrop.svg";

const CustomDropdown = ({ onSelect, placeholder,options}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [error, setError] = useState(null); // State for error handling
  const dropdownRef = useRef(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option); // Set the selected option
    setIsOpen(false); // Close the dropdown
    onSelect(option); // Pass the selected option to the parent component
  };

  // Close the dropdown if clicked outside
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  if (!options) {
    return <div>Loading...</div>; // Loading state
  }

  if (error) {
    return <div>Error: {error}</div>; // Error state
  }

  return (
    <div className="custom-dropdown" ref={dropdownRef}>
      <div className="custom-dropdown-header" onClick={handleToggle}>
        {/* Display the selected option's title or the placeholder */}
        {selectedOption ? selectedOption?.attributes?.title : placeholder}
        <img src={Arrow} alt="arrow" width={12} />
      </div>

      {isOpen && (
        <ul className="custom-dropdown-list">
          {options.map((option) => (
            <li
              key={option.id}
              className="custom-dropdown-item"
              onClick={() => handleOptionClick(option)}
            >
              {option?.attributes?.title}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomDropdown;