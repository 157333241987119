import { CloseRounded } from "@mui/icons-material";
import { Box, Container, IconButton, Modal, Stack } from "@mui/material";

export const VideoPopup = ({ open, onClose }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Container maxWidth="md">
                <Stack
                    height="100dvh"
                    justifyContent="center"
                    alignItems="stretch"
                    width={1}
                >
                    <Stack width={1} position="relative">
                        <IconButton
                            sx={{
                                top: -50,
                                right: 0,
                                position: "absolute",
                                background: "white",
                                ":hover": {
                                    background: "white",
                                },
                            }}
                            onClick={onClose}
                        >
                            <CloseRounded
                                sx={{
                                    color: "black",
                                }}
                            />
                        </IconButton>

                        <Box
                            component="video"
                            controls
                            playsInline
                            sx={{
                                width: 1,
                                objectFit: "cover",
                            }}
                        >
                            <source src={open} />
                        </Box>
                    </Stack>
                </Stack>
            </Container>
        </Modal>
    );
};
