import { useEffect, useState } from "react";
// import { GoogleMaps } from "../../../components";
import { GM } from "Pages/ChargingNetwork/components/GM";

import Location from "assets/hicity/location.svg";
import Direction from "assets/hicity/direction.svg";

export const HiCityOurNetwork = ({ storeData }) => {
    const [state, setState] = useState({
        city: "New Delhi",
        state: "Delhi",
    });
    const [stateData, setStateData] = useState([]);

    const getCity = (state) => {
        let city = storeData
            ?.filter(
                (item) =>
                    item?.attributes?.state_located?.data?.attributes
                        ?.state_name == state
            )
            ?.map(
                (item) =>
                    item?.attributes?.city_located?.data?.attributes?.city_name
            );

        const filteredCities = city.filter(
            (city) => city && city.trim() !== ""
        );
        const uniqueCity = Array.from(new Set(filteredCities));

        return uniqueCity;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setState({
            ...state,
            [name]: value,
        });
        if (name == "state") {
            setState({
                state: value,
                city: "",
            });
        }
    };

    const filterStateData = () => {
        const states = storeData?.map(
            (item) =>
                item?.attributes?.state_located?.data?.attributes?.state_name
        );

        const filteredStates = states?.filter(
            (state) => state && state?.trim() !== ""
        );

        const uniqueStates = Array.from(new Set(filteredStates));

        setStateData(uniqueStates);
    };

    useEffect(() => {
        filterStateData();
    }, [state?.state, state?.city, storeData]);

    return (
        <section id="our_network" className="network_section bg_secondary">
            <div className="custom-container">
                <div className="network_header">
                    <h4 className="secondary_heading">
                        Our <span>Network</span>
                    </h4>
                </div>
                <div className="network_wrap">
                    <div className="network_col _left">
                        <h5 className="heading">Our Network</h5>
                        <div className="network_form">
                            <form action="">
                                <div className="form-group">
                                    <select
                                        className="form-control"
                                        name="state"
                                        onChange={handleChange}
                                        value={state?.state}
                                    >
                                        <option value="None">
                                            Select State
                                        </option>
                                        {storeData &&
                                            stateData?.map((item) => (
                                                <option value={item} key={item}>
                                                    {item}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <select
                                        className="form-control"
                                        name="city"
                                        onChange={handleChange}
                                        value={state?.city}
                                    >
                                        <option value="None">
                                            Select City
                                        </option>
                                        {state?.state && (
                                            <>
                                                {getCity(state?.state)?.map(
                                                    (item) => (
                                                        <option
                                                            key={item}
                                                            value={item}
                                                        >
                                                            {item}
                                                        </option>
                                                    )
                                                )}
                                            </>
                                        )}
                                    </select>
                                </div>
                            </form>
                        </div>
                        <div className="network_result_wrap">
                            <div className="result_heading">
                                Result For Euler Network :
                            </div>
                            <div className="network_result">
                                {storeData
                                    ?.filter(
                                        (item) =>
                                            item?.attributes?.state_located
                                                ?.data?.attributes
                                                ?.state_name == state?.state &&
                                            item?.attributes?.city_located?.data
                                                ?.attributes?.city_name ==
                                                state?.city
                                    )
                                    ?.map((item, index) => (
                                        <div
                                            key={index}
                                            className="network_result_row"
                                        >
                                            <div className="location_name">
                                                {item?.attributes?.name}
                                            </div>
                                            <div className="location_address">
                                                {
                                                    item?.attributes
                                                        ?.store_address
                                                }
                                            </div>
                                            <div className="result_btn">
                                                <a
                                                    className="theme-btn-link"
                                                    target="_blank"
                                                    href={`https://www.google.com/maps/?q=${item?.attributes?.latitude},${item?.attributes?.longitude}`}
                                                >
                                                    <sapn className="btn_icon">
                                                        <img
                                                            className="img-fluid"
                                                            src={Location}
                                                            alt=""
                                                        />
                                                    </sapn>
                                                    View on Map
                                                </a>
                                                <a
                                                    className="theme-btn-link"
                                                    target="_blank"
                                                    href={`https://www.google.com/maps/dir/?api=1&destination=${item?.attributes?.latitude},${item?.attributes?.longitude}`}
                                                >
                                                    <sapn className="btn_icon">
                                                        <img
                                                            className="img-fluid"
                                                            src={Direction}
                                                            alt=""
                                                        />
                                                    </sapn>
                                                    Get Directions
                                                </a>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                    <div className="network_col _right">
                        <GM
                            isMarkDown
                            chargingHubs={storeData}
                            small={false}
                            mapElement={
                                <div
                                    style={{
                                        border: 0,
                                        width: "100%",
                                        height: "100%",
                                    }}
                                />
                            }
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};
