import { useEffect, useMemo, useState } from "react";
import { apiClient, HOST } from "api/apiClient";
import CitiesJson from "assets/json/india-state-city.json";
import UsageCitiesJson from "assets/json/service_city.json";

export const CamelToWord = (text) => {
  const result = text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const productTypeMapping = {
  "HiLoad EV": "3WC",
  "StormEV LongRange200": "4WCLR",
  "StormEV T1250": "4WC",
}
export const videoLink =
  "https://www.eulermotors.com/admin/cmsimages/372589charging.mp4";

export const OVERLAY_DATA = {
  0: {
    main: "Payload",
    desc: "688kgs Payload Capacity",
  },
  1: {
    main: "Range",
    desc: "151kms Range",
  },
  2: {
    main: "Charge",
    desc: "50kms range in 15 minutes",
  },
  3: {
    main: "Payload",
    desc: "688kgs Payload Capacity",
  },
  4: {
    main: "Range",
    desc: "151kms Range",
  },
};

const options = {
  rootMargin: "-50%",
};

export function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => setIsIntersecting(entry.isIntersecting),
        options
      ),
    []
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}

export const openInNewTab = (url) => {
  if (url) {
    const newWindow = window.open(
      `${HOST}/${url}`,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  }
};

export const downloadBroucher = async () => {
  const res = await apiClient.getBroucher();
  if (res) {
    openInNewTab(res?.data?.[0]?.attributes?.broucher?.data?.attributes?.url);
  }
};

export const FORMATTER = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "INR",

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 0,
  // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const OBJ = {
  DV: {
    Model: {
      "Kerb Weight": "690 Kgs",
      "Overall Length": "3400 mm",
      "Overall Height": "2100 mm",
      "Overall Width": "1640 mm",
      "Gross Vehicle Weight": "1413 Kgs",
      "Container Dimension (lxbxh) ft": "6.1 x 4.8 x 4.4 | 130 cuft",
    },

    Motor: {
      Type: "Three phase induction motor",
      "Power (peak)": "10.96 KW",
      Transmission: "Automatic",
      "Torque (peak)": "88.55 Nm",
    },
    Features: {
      Tyres: "Radial Tubeless: 5.5-12",
      "Head Lights": "Projector head lamps",
      "Water Wading Height": "1 Foot",
      "Regenerative Braking": "✔",
      "Vehicle Tracking System": "✔",
      "Water and Dust Proof Battery (IP67)": "✔",
      "Battery Thermal Management System (Liquid Cooling)": "✔",
    },
    Performance: {
      "True Range*": "100 _+ 10 Km",
      "Charging Time": "AC charging : 3.5 - 4 hours",
      "Maximum Speed": "45 Km/hr",
      "Certified Range": "170 Km",
      "On-board Charger":
        "Plug and play charging gun. No extra hassle or components",
      "Certified Payload": "655 Kg",
      "DC Fast Charging Feature": "50 km in 15 minutes",
    },
    "Brake System": {
      Rear: "Drum Brake",
      Type: "Hydraulic",
      Front: "Disc Brake",
      Parking: "Mechanical lever",
    },
    Specifications: {
      Type: "L5N",
      Wheelbase: "2200 mm",
      "Cabin Type": "Reinforced Sheet Metal",
      Gradeability: "21% / 9.45 degrees",
      "Overall Width": "1460 mm",
      "Overall Length": "3400 mm",
      "Turning Radius": "3.5 m",
      "Steering System": "Handle Bar",
      "Ground Clearance": "300 mm",
      "Seating Capacity": "D + Cargo",
    },
    "Battery Powerpack": {
      Capacity: "12.96 kWh",
      "Pack Voltage": "72 V",
      "Battery Type / Location": "Lithium-ion battery / Chassis integration",
    },
    "Suspension systems": {
      Rear: "Trailing arm with hydraulic shock absorber with helical spring",
      Front: "Hydraulic shock absorber with helical spring",
    },
  },
  PV: {
    Model: {
      "Kerb Weight": "618 Kgs",
      "Overall Length": "3400 mm",
      "Overall Height": "1800 mm",
      "Overall Width": "1640 mm",
      "Gross Vehicle Weight": "1413 Kgs",
      "Container Dimension (lxbxh) ft": "6.1 x 4.8 x Open",
    },
    Motor: {
      Type: "Three phase induction motor",
      "Power (peak)": "7.1 KW",
      Transmission: "Automatic",
      "Torque (peak)": "88.55 Nm",
    },
    Features: {
      Tyres: "Radial Tubeless: 5.5-12",
      "Head Lights": "Projector head lamps",
      "Water Wading Height": "1 Foot",
      "Regenerative Braking": "✔",
      "Vehicle Tracking System": "✔",
      "Water and Dust Proof Battery (IP67)": "✔",
      "Battery Thermal Management System (Liquid Cooling)": "✔",
    },
    Performance: {
      "True Range*": "100 _+ 10 Km",
      "Charging Time": "AC charging : 3.5 - 4 hours",
      "Maximum Speed": "45 Km/hr",
      "Certified Range": "170 Km",
      "On-board Charger":
        "Plug and play charging gun. No extra hassle or components",
      "Certified Payload": "688 Kg",
      "DC Fast Charging Feature": "50 km in 15 minutes",
    },
    "Brake System": {
      Rear: "Drum Brake",
      Type: "Hydraulic",
      Front: "Disc Brake",
      Parking: "Mechanical lever",
    },
    Specifications: {
      Type: "L5N",
      Wheelbase: "2200 mm",
      "Cabin Type": "Reinforced Sheet Metal",
      Gradeability: "21% / 9.45 degrees",
      "Overall Width": "1460 mm",
      "Overall Length": "3400 mm",
      "Turning Radius": "3.5 m",
      "Steering System": "Handle Bar",
      "Ground Clearance": "300 mm",
      "Seating Capacity": "D + Cargo",
    },
    "Battery Powerpack": {
      Capacity: "12.96 kWh",
      "Pack Voltage": "72 V",
      "Battery Type / Location": "Lithium-ion battery / Chassis integration",
    },
    "Suspension systems": {
      Rear: "Trailing arm with hydraulic shock absorber with helical spring",
      Front: "Hydraulic shock absorber with helical spring",
    },
  },
  HD: {
    Model: {
      "Kerb Weight": "645 Kgs",
      "Overall Length": "3400 mm",
      "Overall Height": "2100 mm",
      "Overall Width": "1640 mm",
      "Gross Vehicle Weight": "1413 Kgs",
      "Container Dimension (lxbxh) ft": "6.1 x 4.8 x Open",
    },
    Motor: {
      Type: "Three phase induction motor",
      "Power (peak)": "7.1 KW",
      Transmission: "Automatic",
      "Torque (peak)": "88.55 Nm",
    },
    Features: {
      Tyres: "Radial Tubeless: 5.5-12",
      "Head Lights": "Projector head lamps",
      "Water Wading Height": "1 Foot",
      "Regenerative Braking": "✔",
      "Vehicle Tracking System": "✔",
      "Water and Dust Proof Battery (IP67)": "✔",
      "Battery Thermal Management System (Liquid Cooling)": "✔",
    },
    Performance: {
      "True Range*": "100 _+ 10 Km",
      "Charging Time": "AC charging : 3.5 - 4 hours",
      "Maximum Speed": "45 Km/hr",
      "Certified Range": "170 Km",
      "On-board Charger":
        "Plug and play charging gun. No extra hassle or components",
      "Certified Payload": "688 Kg",
      "DC Fast Charging Feature": "50 km in 15 minutes",
    },
    "Brake System": {
      Rear: "Drum Brake",
      Type: "Hydraulic",
      Front: "Disc Brake",
      Parking: "Mechanical lever",
    },
    Specifications: {
      Type: "L5N",
      Wheelbase: "2200 mm",
      "Cabin Type": "Reinforced Sheet Metal",
      Gradeability: "21% / 9.45 degrees",
      "Overall Width": "1460 mm",
      "Overall Length": "3400 mm",
      "Turning Radius": "3.5 m",
      "Steering System": "Handle Bar",
      "Ground Clearance": "300 mm",
      "Seating Capacity": "D + Cargo",
    },
    "Battery Powerpack": {
      Capacity: "12.96 kWh",
      "Pack Voltage": "72 V",
      "Battery Type / Location": "Lithium-ion battery / Chassis integration",
    },
    "Suspension systems": {
      Rear: "Trailing arm with hydraulic shock absorber with helical spring",
      Front: "Hydraulic shock absorber with helical spring",
    },
  },
};

export const sortData = (data, type = "order") =>
  data.sort((a, b) => a.attributes[type] - b.attributes[type]);
export const sortDataById = (data) => data.sort((a, b) => a.id - b.id);

export const getStateCity = () => {
  let StateCity = [];

  for (let i in CitiesJson) {
    StateCity = [...StateCity, CitiesJson[i]];
  }
  return StateCity;
};

export const getStates = () => Object.keys(CitiesJson);
export const getUsageStates = () => Object.keys(UsageCitiesJson);
export const getCityOfState = (state) => CitiesJson[state];
export const getCityOfUsageState = (state) => UsageCitiesJson[state];
// export const getShowroom = (state, city) =>
//   UsageCitiesJson[state] ? UsageCitiesJson[state][0][city] : [];
export const getShowroom = (state, city) => {
  let showroomData = [];
  for (const key in UsageCitiesJson) {
    if (key === state || key === city) {
      const cities = UsageCitiesJson[key];
      for (const data of cities) {
        const cityData = data[state] || data[city];
        if (cityData) {
          showroomData = showroomData.concat(cityData);
        }
      }
    }
  }
  return showroomData;
};

export const generateUid = (type) => {
  const timestamp = new Date().getTime();
  switch (type) {
    case "RIDE":
      return type + timestamp;
    case "BK":
      return type + timestamp;
    case "PROSPECT":
      return type + timestamp;
    default:
      return timestamp;
  }
};

export const TRUCKS = {
  "3 Wheeler": {
    Mahindra: ["Alfa Plus", "Treo Zor", "others"],
    Piaggio: ["Ape Xtra LDX", "others"],
    TVS: ["King Kargo", "others"],
    Bajaj: ["Maxima", "others"],
    others: ["others"],
  },
  "4 Wheeler": {
    "Tata Motors": ["Ace", "Intra", "Gold", "others"],
    "Ashok Leyland": ["Dost", "BadaDost", "other"],
    Maruti: ["Super Carry", "Eeco Cargo", "others"],
    Mahindra: ["Bolero PickUp", "Jeeto", "MAxitruck"],
    others: ["others"],
  },
};

export const getTrucks = Object.keys(TRUCKS);
export const getBrands = () => getTrucks.map((item) => TRUCKS[item]);

export const ERROR_CODES = {
  invalid: { email: "Invalid email ", pincode: "Invalid pincode " },
  empty: "Please fill up the mandatory fields",
  verifyPhone: "Please verify your phone",
  emailMisMatch: "Confirm email mismatch",
  otpMisMatch: "Invalid Otp",
  invalidPhone: "Invalid Phone Number",
  mobileVerified: "Mobile No. already verified",
  OtpError: "OTP was not sent! Please try again",
  OtpExpired: "Otp Expired",
  OtpVerified: "Already Verified, please try again in 1 mins!",
  alredySubscribed: "Already Subscribed!",
  pendingPayment:
    "Your payment is pending. Contact our helpline at 1800 1238 1238 to resolve this issue.",
  notServiceable:
    "We are currently not serviceable in this location. We will reach out to you shortly.",
};

export const isValidEmail = (email) => {
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    email
  );
};

export const isValidPincode = (pin) => {
  return /[1-9]{6}/.test(pin);
};

export const DIESEL_COST = 92;
export const PETROL_COST = 96;
export const CNG_COST = 86;

export const MONTHS = [
  {
    id: 1,
    month: "January",
  },
  {
    id: 2,
    month: "February",
  },
  {
    id: 3,

    month: "March",
  },
  {
    id: 4,

    month: "April",
  },
  {
    id: 5,

    month: "May",
  },
  {
    id: 6,

    month: "June",
  },
  {
    id: 7,

    month: "July",
  },
  {
    id: 8,
    month: "August",
  },
  {
    id: 9,

    month: "September",
  },
  {
    id: 10,
    month: "October",
  },
  {
    id: 11,

    month: "November",
  },
  {
    id: 12,

    month: "December",
  },
];

const currentYear = new Date().getFullYear();

export const YEAR = [4, 3, 2, 1, 0].map((item) => ({
  year: currentYear - item,
}));

export const metaMapWithSlug = (slug) => {
  switch (slug) {
    case "high-purchase-price-of-an-electric-vehicle-consider-lower-tco-before-making-the-decision": {
      return {
        title:
          "Worried about high purchase cost of an Electric Vehicle? Consider Lower Total Cost Ownership before making the decision to earn more and save more.",
        description:
          "An EV’s high upfront cost is easily offset by lower operating costs (both running & maintenance), which is almost negligible when compared to an ICE, irrespective of the segment.",
        slug,
      };
    }
    case "is-lab-certified-range-enough-to-build-consumer-confidence-in-electric-vehicles": {
      return {
        title: "Concerned about Lower Range of Electric Vehicles?",
        description:
          "At Euler Motors as an EV OEM we are addressing the real-life experience and concern in the context of EV range. Both EVs and ICEs undergo homologation or lab tests to determine their performance and range specifications.",
        slug,
      };
    }
    case "no-retrofitting-industry-needs-a-novel-approach-to-electric-vehicle-financing": {
      return {
        title:
          "No Retrofitting : Industry needs a novel approach to electric vehicle financing",
        description:
          "An Reported by Niti Ayog and RMI India centered around EV financing states. The EV financing market will be at a size of USD 50 billion in 2030-about 80% of the current size of India’s retail vehicle finance industry.",
        slug,
      };
    }
    case "product-technology-and-data-the-elements-of-a-differentiated-ev-customer-experience": {
      return {
        title:
          "Product Technology and Data: The elements of a Differentiated EV customer experience",
        description:
          "A successful customer experience is driven by data, which often is missed in the larger play of things. Data analytics has tremendous potential for EVs to function.",
        slug,
      };
    }
    case "why-should-financiers-look-at-expanding-customers-via-ev-leasing": {
      return {
        title:
          "Why should financiers look at expanding customers via EV leasing",
        description:
          "The lease model for EVs is one of the most attractive avenues for financiers to expand their institutional customer portfolio.",
        slug,
      };
    }
  }
};
