import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//
import Slider from "react-slick";
import { useMediaQuery } from "@mui/system";
import { useEffect, useRef, useState } from "react";
//
import { endpoints } from "api/endpoints";
import { GET_REQUEST } from "api/api-services";
import Broucher from "Pages/components/Broucher";

export const HiCityBannerSection = () => {
    const sliderRef = useRef(null);
    const small = useMediaQuery("(max-width: 786px)");

    const [bannerData, setBannerData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openBrochureForm, setOpenBrochureForm] = useState(false);

    const sliderOptions = {
        ref: sliderRef,
        dots: false,
        arrows: false,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 5000,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                },
            },
            // {
            //     breakpoint: 1024,
            //     settings: {
            //         slidesToShow: 3,
            //         slidesToScroll: 3,
            //         dots: true,
            //     },
            // },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
        ],
    };

    const fetchBannerData = async () => {
        try {
            const response = await GET_REQUEST(endpoints["banner_data"]);
            if (response?.status) {
                setBannerData(response?.data);
                setIsLoading(false);
            } else {
                throw new Error(
                    response?.message ||
                        `HTTP error! status: ${response.status}`
                );
            }
        } catch (error) {
            setError(error.message);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchBannerData();
    }, []);

    return (
        <>
            <section className="header">
                <div className="banner_section">
                    <Slider {...sliderOptions} className="banner_slider">
                        {bannerData?.data?.map((item, index) => (
                            <div className="slick-slide" key={index}>
                                <div className="slide_wrap">
                                    <div className="d-lg">
                                        <div className="banner_img_wrap">
                                            <img
                                                className="img-fluid"
                                                src={
                                                    item?.attributes
                                                        ?.BannerImage?.data
                                                        ?.attributes?.url
                                                }
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    <div className="d-xs">
                                        <div className="banner_img_wrap">
                                            <img
                                                className="img-fluid"
                                                src={
                                                    item?.attributes
                                                        ?.BannerMobileImage
                                                        ?.data?.attributes?.url
                                                }
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    <div className="banner_content_wrap">
                                        <h1 className="primary_heading">
                                            <span>
                                                {item?.attributes
                                                    ?.BannerTitle ||
                                                    "Power Meets Performance:"}
                                            </span>
                                            <br />
                                            {item?.attributes?.BannerSubtitle ||
                                                "Discover the Features of Our Electric Trucks"}
                                        </h1>
                                        <a
                                            href={
                                                item?.attributes
                                                    ?.BannerTestRideUrl
                                            }
                                            className="theme-btn theme-btn-primary"
                                            onClick={(event) => {
                                                if (
                                                    !!item?.attributes
                                                        ?.BannerTestRideMedia
                                                        ?.data
                                                ) {
                                                    event.preventDefault();
                                                    setOpenBrochureForm(true);
                                                }
                                            }}
                                        >
                                            {
                                                item?.attributes
                                                    ?.BannerBookTestButton
                                            }
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>

                    {/* <div className="swiper-pagination-banner" /> */}

                    <ul className="banner_btn d-lg">
                        <li>
                            <button
                                className="banner-button-prev"
                                onClick={() => sliderRef?.current?.slickPrev()}
                                type="button"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={28}
                                    height={28}
                                    viewBox="0 0 28 28"
                                    fill="none"
                                >
                                    <path
                                        d="M16.5159 4.33476L7.95793 12.8927C7.83826 13.012 7.7433 13.1537 7.67851 13.3097C7.61372 13.4658 7.58037 13.633 7.58037 13.802C7.58037 13.9709 7.61372 14.1382 7.67851 14.2943C7.7433 14.4503 7.83826 14.592 7.95793 14.7113L16.5159 23.2692C16.757 23.5104 17.0841 23.6459 17.4252 23.6459C17.7662 23.6459 18.0933 23.5104 18.3345 23.2692C18.5756 23.0281 18.7111 22.701 18.7111 22.36C18.7111 22.0189 18.5756 21.6918 18.3345 21.4507L10.6847 13.8009L18.3355 6.15119C18.5767 5.91003 18.7122 5.58295 18.7122 5.2419C18.7122 4.90086 18.5767 4.57378 18.3355 4.33262C18.0944 4.09146 17.7673 3.95598 17.4262 3.95598C17.0852 3.95598 16.7581 4.09146 16.517 4.33262L16.5159 4.33476Z"
                                        fill="currentColor"
                                    />
                                </svg>
                            </button>
                        </li>
                        <li>
                            <button
                                className="banner-button-next"
                                onClick={() => sliderRef?.current?.slickNext()}
                                type="button"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={29}
                                    height={28}
                                    viewBox="0 0 29 28"
                                    fill="none"
                                >
                                    <path
                                        d="M12.084 4.33476L20.6419 12.8927C20.7616 13.012 20.8565 13.1537 20.9213 13.3097C20.9861 13.4658 21.0195 13.633 21.0195 13.802C21.0195 13.9709 20.9861 14.1382 20.9213 14.2943C20.8565 14.4503 20.7616 14.592 20.6419 14.7113L12.084 23.2692C11.8428 23.5104 11.5157 23.6459 11.1747 23.6459C10.8336 23.6459 10.5066 23.5104 10.2654 23.2692C10.0242 23.0281 9.88877 22.701 9.88877 22.36C9.88877 22.0189 10.0242 21.6918 10.2654 21.4507L17.9151 13.8009L10.2643 6.15119C10.0232 5.91003 9.88769 5.58295 9.88769 5.2419C9.88769 4.90086 10.0232 4.57378 10.2643 4.33262C10.5055 4.09146 10.8326 3.95598 11.1736 3.95598C11.5147 3.95598 11.8417 4.09146 12.0829 4.33262L12.084 4.33476Z"
                                        fill="currentColor"
                                    />
                                </svg>
                            </button>
                        </li>
                    </ul>
                </div>
            </section>

            <Broucher
                open={openBrochureForm}
                handleClose={() => setOpenBrochureForm(false)}
                small={small}
                hicity
            />
        </>
    );
};
