/* eslint-disable */
import { Modal } from "@mui/material";
import { apiClient } from "api/apiClient";
import Close from "assets/close.svg";
import PDF_EN from "assets/pdf/Brochure_en.pdf";
import PDF_GU from "assets/pdf/Brochure_gu.pdf";
import PDF_HI from "assets/pdf/Brochure_hi.pdf";
import PDF_KN from "assets/pdf/Brochure_kn.pdf";
import PDF_MR from "assets/pdf/Brochure_mr.pdf";
import PDF_TA from "assets/pdf/Brochure_ta.pdf";
import PDF_TE from "assets/pdf/Brochure_te.pdf";
import Right from "assets/rightPath.svg";
import { useLanguage } from "LanguageContext";
import { useEffect, useState } from "react";
import Box from "ReusableComponents/Box";
import Text from "ReusableComponents/Text";
import { COLORS, SIZES, WEIGHT } from "theme";
import { getCityOfState, getStates } from "utils";
import CustomDropdown from "./dropdown";
import "./style.css";

const Broucher = ({ open, handleClose, small, hicity = false }) => {
  const [state, setState] = useState({
    name: "",
    first_name: "",
    last_name: "",
    email_address: "",
    phone_number: "",
    state: "",
    city: "",
    product: "",
    utm_medium: "",
    utm_source: "",
    utm_campaign: "",
    gclid: "",
  });

  const [options, setOptions] = useState([]);
  const [cursor, setCursor] = useState("not-allowed");
  const [validationError, setValidationError] = useState("");
  const { selectedLanguage } = useLanguage();
  const [selectedOption, setSelectedOption] = useState(null);
  const [url, setUrl] = useState("");
  const [downloadLoading, setDownloadLoading] = useState(false);

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://cms.eulermotors.com/api/products?populate=*"
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setOptions(data.data); // Assuming the response is an array of options
      // setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error.message);
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSelect = (option) => {
    setSelectedOption(option); // Update the selected PDF option
  };
  useEffect(() => {
    const utm_medium = sessionStorage.getItem("utm_medium");
    const utm_source = sessionStorage.getItem("utm_source");
    const utm_campaign = sessionStorage.getItem("utm_campaign");
    const gclid = sessionStorage.getItem("gclid");

    if (utm_medium || utm_source || utm_campaign) {
      setState({ ...state, utm_medium, utm_source, utm_campaign, gclid });
    }
    setCursor(checkCursor());
  }, [state]);

  const handleChange = (e) => {
    if (e.target.value === "none") return;
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const checkCursor = () => {
    let isFilled = true;
    for (let i in state) {
      if (!state[i]) {
        isFilled = false;
      }
    }
    if (!isFilled) return false;
    return true;
  };

  function validatePhoneNumber(phoneNumber) {
    const regex = /^\d{10}$/; // regular expression to match 10 digits only
    return regex.test(phoneNumber);
  }

  const submit = async () => {
    console.log("downloadPdfFile", selectedOption);
    if (selectedOption) {
      const url = selectedOption.attributes.pdf.data.attributes.url; // The PDF URL
      const fileName = selectedOption.attributes.title; // Specify the file name
      // Fetch the file as a blob, then create a download link
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          if (blob) {
            const link = document.createElement("a");
            const blobUrl = window.URL.createObjectURL(blob); // Create a blob URL
            link.href = blobUrl;
            link.setAttribute("target", "_blank");
            link.setAttribute("download", fileName); // Force the download with the specified filename
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobUrl); // Clean up the blob URL
          }
        })
        .catch((error) => {
          console.error("Error downloading the PDF file:", error);
        });
    } else {
      console.error("No option selected for download.");
    }

    if (!validatePhoneNumber(state.phone_number)) {
      setValidationError("Please Enter the valid phone number.");
      return;
    } else if (
      !state.name ||
      // !state.email_address ||
      !state.phone_number ||
      !state.city
    ) {
      setValidationError("Please fill up the mandatory fields");
      console.log(validationError);
      return;
    } else {
      setDownloadLoading(true);
      apiClient
        .sendBrouchure({
          ...state,
          name: state?.name,
          product: selectedOption?.attributes?.title,
          email_address: 'no-reply@eulermotors.com'
        })
        .then((res) => {
          if (
            selectedOption?.attributes?.title?.toLowerCase().includes("hiload")
          ) {
            window.open(
              selectedLanguage == "en"
                ? PDF_EN
                : selectedLanguage == "hi"
                ? PDF_HI
                : selectedLanguage == "gu"
                ? PDF_GU
                : selectedLanguage == "kn"
                ? PDF_KN
                : selectedLanguage == "mr"
                ? PDF_MR
                : selectedLanguage == "ta"
                ? PDF_TA
                : selectedLanguage == "te"
                ? PDF_TE
                : PDF_EN,
              "_blank"
            );
          }
          window.dataLayer = window.dataLayer || [];

          window.dataLayer.push({
            event: "Download Brochure",
            formType: "Brochure_form",
          });

          console.log("Download Broucher Event Fired")
          setTimeout(() => {
            handleClose();
          }, 2000);

          
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        background="#F4F4F4 0% 0% no-repeat padding-box"
        boxShadow="0px 6px 36px #0000001A"
        w={small ? "auto" : "500px"}
        textAlign="center"
        position="relative"
        margin="20px auto"
        display="flex"
        justifyContent="center"
        padding="24px"
        borderRadius="6px"
      >
        <img
          src={Close}
          alt="close"
          style={{
            position: "absolute",
            right: 10,
            top: 10,
            cursor: "pointer",
          }}
          onClick={handleClose}
        />
        <Box disply="flex" flexDirection="column" w="-webkit-fill-available">
          <Text
            fontSize={small ? SIZES.fourteen : "24px"}
            fontWeight="bold"
            margin={SIZES.zero}
          >
            {hicity ? "Enquire now" : "Brochure"}
          </Text>
          <form style={{ margin: small && "0 auto" }}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="start"
              alignItems="start"
            >
              <Text fontSize={small ? SIZES.twelve : "18px"} fontWeight="bold">
                Name *
              </Text>
              <input
                name="name"
                required
                value={state.name}
                onChange={handleChange}
                type="text"
                placeholder="Enter Name"
                style={{
                  padding: small ? SIZES.twelve : "12px 24px",
                  width: "100%",
                  fontSize: small ? SIZES.twelve : 18,
                }}
              />
            </Box>
            {/* <Box
              display="flex"
              flexDirection="column"
              justifyContent="start"
              alignItems="start"
            >
              <Text ontSize={small ? SIZES.twelve : "18px"} fontWeight="bold">
                Email ID *
              </Text>
              <input
                name="email_address"
                onChange={handleChange}
                type="email"
                placeholder="Enter Email ID"
                required
                style={{
                  padding: small ? SIZES.twelve : "12px 24px",
                  width: "100%",
                  fontSize: small ? SIZES.twelve : 18,
                }}
              />
            </Box> */}

            <Box
              display="flex"
              flexDirection="column"
              justifyContent="start"
              alignItems="start"
            >
              <Text ontSize={small ? SIZES.twelve : "18px"} fontWeight="bold">
                Select Product *
              </Text>

              {/* Dropdown Component */}
              <CustomDropdown
                options={options}
                onSelect={handleSelect}
                placeholder="Select"
                name="product"
                required
                value={state.product}
                onChange={handleChange}
              />
              {/* <input
                name="email_address"
                onChange={handleChange}
                type="email"
                placeholder="Enter Email ID"
                required
                style={{
                  padding: small ? SIZES.twelve : "12px 24px",
                  width: "100%",
                  fontSize: small ? SIZES.twelve : 18,
                }}
              /> */}

              <div></div>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              justifyContent="start"
              alignItems="start"
            >
              <Text ontSize={small ? SIZES.twelve : "18px"} fontWeight="bold">
                Phone Number *
              </Text>
              <input
                type="text"
                name="phone_number"
                onChange={handleChange}
                placeholder="Enter Number"
                style={{
                  padding: small ? SIZES.twelve : "12px 24px",
                  width: "100%",
                  fontSize: small ? SIZES.twelve : 18,
                }}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="start"
              alignItems="start"
            >
              <Text ontSize={small ? SIZES.twelve : "18px"} fontWeight="bold">
                State *
              </Text>
              <select
                name="state"
                onChange={handleChange}
                style={{
                  padding: "12px 24px",
                  width: small ? "-webkit-fill-available" : 450,
                  border: "1px solid black",
                  background: COLORS.white,
                  color: "black",
                }}
              >
                {getStates().map((item, id) => (
                  <option key={id} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="start"
              alignItems="start"
            >
              <Text ontSize={small ? SIZES.twelve : "18px"} fontWeight="bold">
                City *
              </Text>
              <select
                name="city"
                onChange={handleChange}
                value={state.city}
                style={{
                  padding: "12px 24px",
                  width: small ? "-webkit-fill-available" : 450,
                  border: "1px solid black",
                  background: COLORS.white,
                  color: "black",
                }}
              >
                <option value={"none"} selected>
                  Select City
                </option>
                {state.state &&
                  getCityOfState(state.state).map((item) => (
                    <option key={item} value={item} defaultValue={item}>
                      {item}
                    </option>
                  ))}
              </select>
            </Box>

            <Box
              background={downloadLoading ? "#cccccc" : "#9CCDFB"}
              display="flex"
              w="fit-content"
              padding="0 12px"
              alignItems="center"
              justifyContent="center"
              cursor={downloadLoading ? "not-allowed" : "pointer"}
              margin="24px auto"
              onClick={downloadLoading ? null : submit}
            >
              <Text ontSize={small ? SIZES.fourteen : "18px"} fontWeight="bold">
                Download Brochure
              </Text>
              <img src={Right} alt="right" style={{ margin: "0 8px" }} />
            </Box>
            {validationError && (
              <p style={{ color: "red", fontWeight: WEIGHT.bold }}>
                {validationError}
              </p>
            )}
          </form>
        </Box>
      </Box>
    </Modal>
  );
};

export default Broucher;
